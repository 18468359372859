/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SectionTitleText from '@common/SectionTitleText'
import DoubleColumnsContainer from '@containers/DoubleColumnsContainer'
import createSymbolBackgroundStyle from '@common/backgrounds/createSymbolBackgroundStyle'
import YoutubeVideo from '@common/videoPlayers/YoutubeVideo'

const MainWrapper = styled.div`
  margin: 0 auto;
  width: 94%;
  position: relative;
  &::before {
    ${createSymbolBackgroundStyle({
      width: '70%',
      height: '110%',
      right: '-55px',
      top: '-35px',
    })}
  }
  ${props => props.theme.media.lessThan('990px')`
    &::before {
      top: 23%;
      height: 90%;
    }
  `}
  ${props => props.theme.media.lessThan('mobile')`
    &::before {
      height: 106%;
      right: -10%;
      top: 14%;
    }
`}
`
const TitleWrapper = styled.div`
  ${props => props.theme.flex.display};
  justify-content: center;
  margin: 0 0 0 40px;
  & * {
    text-shadow: none;
  }
  ${props => props.theme.media.lessThan('1000px')`
      & > h1 {
        width: 100%
        
      }
    `}
  ${props => props.theme.media.between('mobile', 'tablet')`
    width: 90%;
  `}
  ${props => props.theme.media.lessThan('tablet')`
    margin: 0 auto;
  `}
`
const videoTitleExtraStyles = `
  font-size: 38px;
  & > span {
    background: white;
  }
  
`

// const MarkedInTitleWrapper = styled.span`
//   color: ${props => props.theme.color.tinyLightBlue};
// `

const BibleVideo = ({ videoDesc, videoId }) => {
  return (
    <MainWrapper>
      <DoubleColumnsContainer reverseOnMobile>
        <YoutubeVideo videoId={videoId} />
        <TitleWrapper>
          {videoDesc && (
            <SectionTitleText
              text={videoDesc.text}
              extraStyles={videoTitleExtraStyles}
            />
          )}
        </TitleWrapper>
      </DoubleColumnsContainer>
    </MainWrapper>
  )
}

BibleVideo.propTypes = {
  videoId: PropTypes.string,
  videoDesc: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

BibleVideo.defaultProps = {
  videoId: '',
  videoDesc: '',
}

export default BibleVideo
