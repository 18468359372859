/* eslint-disable */
import styled from 'styled-components'

export const MarkedHeader = styled.mark`
  margin-top: 50px;
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSizes.size54};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-transform: uppercase;
  padding: 0;
  line-height: 1.2;

  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size28};
    box-shadow: 10px 0 0 ${props.theme.color.aqua},
      -7px 0 0 ${props.theme.color.aqua};
    line-height: 1.3;
  `}
`
export const MarkedMainInfoHeader = styled(MarkedHeader)`
  background-color: ${props => props.theme.mainSectionMarkedHeaderColor};
  box-shadow: 10px 0 0 ${props => props.theme.mainSectionMarkedHeaderColor},
    -23px 0 0 ${props => props.theme.mainSectionMarkedHeaderColor};
  ${props => props.theme.media.between('mobile', '740px')`
    font-size: ${props => props.theme.fontSizes.size40};
  `}
  ${props => props.theme.media.lessThan('mobile')`
    box-shadow: 10px 0 0 ${props.theme.mainSectionMarkedHeaderColor},
    -7px 0 0 ${props.theme.mainSectionMarkedHeaderColor};
  `}
`

export const MarkedRecipesHeader = styled(MarkedHeader)`
  background-color: ${props => props.theme.recipesMarkedHeaderBGColor};
  box-shadow: 10px 0 0 ${props => props.theme.recipesMarkedHeaderBGColor},
    -23px 0 0 ${props => props.theme.recipesMarkedHeaderBGColor};
  color: ${props => props.theme.recipesMarkedHeaderColor};
  ${props => props.theme.media.lessThan('mobile')`
    box-shadow: 10px 0 0 ${props.theme.recipesMarkedHeaderBGColor},
    -7px 0 0 ${props.theme.recipesMarkedHeaderBGColor};
  `}
`
