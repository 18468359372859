/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SectionTitleText from '@common/SectionTitleText'
import Logo from '@common/Logo'
import createSymbolBackgroundStyle from '@common/backgrounds/createSymbolBackgroundStyle'

const MainWrapper = styled.div`
  width: 94%;
  margin: 0 auto;
  margin-top: 130px;
  & > h1 {
    margin-bottom: 50px;
  }
  position: relative;
  &::before {
    ${createSymbolBackgroundStyle({
      width: '73%',
      height: '20%',
      right: '160px',
      top: '33%',
    })}
  }
  &::after {
    ${createSymbolBackgroundStyle({
      width: '24%',
      height: '67%',
      right: '-5%',
      bottom: '0%',
    })}
  }
  ${props => props.theme.media.lessThan('mobile')`
      &::before, &::after {
        display: none;
      }
  `}
`

const FeedbackWrapper = styled.div`
  height: fit-content;
  width: 330px;
  background: ${props => props.theme.feedbackBGcolor};
  position: relative;
  padding: 25px;
  margin: 15px 0;
  & hr {
    margin: 10px 0;
    border: 1px solid #000000;
  }
  ${props => props.theme.media.lessThan('1120px')`
    margin: 30px 50px 30px 0;
  `}
  ${props => props.theme.media.lessThan('900px')`
      margin: 30px 0 30px 0;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    width: 300px;
  `}
`

const PersonImgWrapper = styled.div`
  position: absolute;
  top: -34px;
  right: 16px;
`

const PersonNameWrapper = styled.h3`
  margin-top: 20px;
  font-family: ${props => props.theme.fontFamily.poppins};
  font-weight: ${props => props.theme.fontWeight.w800};
  font-size: ${props => props.theme.fontSizes.size24};
  width: 80px;
  line-height: 1.15;
  ${props => props.theme.media.lessThan('mobile')`
   font-size: ${props.theme.fontSizes.size16}
  `}
`

const PersonJobWrapper = styled.h3`
  font-size: ${props => props.theme.fontSizes.size14};
  font-weight: ${props => props.theme.fontWeight.bold};
`

const PersonDescWrapper = styled.p`
  font-size: ${props => props.theme.fontSizes.size20};
  line-height: 25px;
  ${props => props.theme.media.lessThan('mobile')`
   font-size: ${props.theme.fontSizes.size16}
   line-height: 22px;
  `}
`

const FeedbacksMapWrapper = styled.div`
  ${props => props.theme.flex.display}
  flex-wrap: wrap;
  justify-content: space-between;
  ${props => props.theme.media.lessThan('900px')`
    flex-direction: column;
    align-items: center;
  `}
`

const titleExtraStyles = `
  text-shadow: none;
`

const BibleFeedback = ({ title, feedbacksMap, socialSrc }) => {
  return (
    <MainWrapper>
      <SectionTitleText text={title} extraStyles={titleExtraStyles} />
      <FeedbacksMapWrapper>
        {!!feedbacksMap.length &&
          feedbacksMap.map(data => {
            if (data.member && data.member.name) {
              return (
                <FeedbackWrapper key={data.member.name}>
                  <a
                    href={data.member.socialLink}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {socialSrc && (
                      <Logo imgSrc={socialSrc} height={32} width={32} />
                    )}
                  </a>
                  <PersonImgWrapper>
                    <Logo imgSrc={data.src} height={143} width={143} />
                  </PersonImgWrapper>

                  <PersonNameWrapper>{data.member.name}</PersonNameWrapper>
                  {data.member.jobTitle && (
                    <PersonJobWrapper>{data.member.jobTitle}</PersonJobWrapper>
                  )}
                  <hr />
                  <PersonDescWrapper>{data.member.text}</PersonDescWrapper>
                </FeedbackWrapper>
              )
            }
          })}
      </FeedbacksMapWrapper>
    </MainWrapper>
  )
}

BibleFeedback.propTypes = {
  feedbacksMap: PropTypes.array,
  title: PropTypes.string,
  socialSrc: PropTypes.string,
}

BibleFeedback.defaultProps = {
  title: '',
  feedbacksMap: [],
  socialSrc: '',
}

export default BibleFeedback
