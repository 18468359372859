import LocalizedStrings from 'react-localization'
import avaliableLangs from './avaliableLangs'

const fbBibleStrings = new LocalizedStrings({
  [avaliableLangs.en]: {
    main: {
      title:
        'The Facebook Bible: The easiest way to skyrocket your performance marketing in 2020',
      desc:
        'Learn from Forbes 30 under 30 honoree and Facebook Guru Patrick Wind how to create, optimize and deliver marketing campaigns that boost your ROAS.',
    },
    buyButton: {
      text: 'DOWNLOAD FOR FREE',
      taxes: 'Price includes all taxes',
    },
    freebieButton: {
      text: 'GET YOUR FREE CHAPTER',
    },
    workedWith: 'PATRICK WORKED WITH THE FOLLOWING',
    videoDesc: {
      text: 'GET A FIRST IMPRESSION ON THE BIBLE’S CONTENT IN THIS VIDEO',
    },

    author: {
      title: 'About the author',
      name: 'Patrick Wind',
      desc: [
        {
          marked: 'Forbes 30 under 30',
          text:
            'Patrick Wind is a Forbes 30 under 30 honoree who provides online training, tools and support to thousands of entrepreneurs growing their businesses with advanced Facebook and Instagram ads.',
        },
        {
          text:
            'Patrick has managed multi-million Euro ad budgets for 120 different brands reaching ad spend returns in excess of 200% in online sales. He also shares his marketing knowledge as a tutor for various Business Schools and Universities all around Europe.',
        },
      ],
    },
    promotion: {
      title: 'Become a Facebook Marketing Expert',
      desc:
        "Is it time to step your Facebook marketing game? Join over 1,000 people who already turned Patrick Wind's insights into Facebook marketing success! Over to you to make the most of more than 100 of Patrick's tips and hacks to supercharge your Facebook marketing.",
    },

    course: {
      title: "Join Europe's #1 Facebook & Instagram Ads Program",
      desc: {
        text:
          'Master the Facebook & Instagram Algorithm to run highly profitable Ads every month and scale them internationally with our unique Full-Funnel Strategy. Within our eLearning Platform you will have immediate access to the full material from your phone, tablet or desktop device, so you can access it whenever and wherever you want.',
        marked: 'highly profitable Ads every month',
      },
      saveMoney: 'Save 50€ with weCreate!',
      moreInfo: 'More information',
      courseLink: 'https://www.adsaccelerator.com/wecreate',
    },

    recipes: {
      title: 'Recipes for Michelin Star Facebook & Instagram Marketing',
      steps: [
        {
          number: '26',
          text: 'hacks to boost the relevance of your ads',
          marked: 'boost the relevance',
        },
        {
          number: '10',
          text: 'commandments of Perfect Ad Design',
          marked: 'commandments',
        },
        {
          number: '5',
          text: 'proven scaling strategies ',
          marked: 'scaling strategies ',
        },
        {
          number: '4',
          text: 'Steps of Daily and Weekly Optimization',
          marked: 'Optimization',
        },
      ],
    },

    coach: {
      title: 'Scale your business with our AdsAccelerator MasterMind',
      desc: '12-Month Coaching Program including',
      list: [
        {
          desc:
            'Weekly MasterMind Group Calls with Patrick Wind or his Mentors',
          marked: 'Weekly MasterMind Group Calls',
        },
        {
          desc: '3x Exclusive 2-Day Workshops in Barcelona (Friday & Saturday)',
          marked: 'Exclusive 2-Day Workshops',
        },
        { desc: 'Private WhatsApp Group', marked: 'Private WhatsApp' },
        {
          desc: 'Premium Access to All Courses of AdsAccelerator',
          marked: 'Premium Access',
        },
      ],
      moreInfo: 'More Information',
      coachLink: 'https://www.adsaccelerator.com/mastermind',
    },

    insideReport: {
      title: 'INSIDE THE REPORT',
      slides: [
        {
          title: 'Top 50 Facebook Audiences',
          text:
            'Explore the Top 50 Facebook audiences and learn to implement a step-by-step strategy to target new, engaged audiences for your business. These audiences will be a starting point for your A/B testing and finding your North Star Metric.',
        },
        {
          title: 'Retargeting Funnel Checklist',
          text:
            'Start using the Retargeting Funnel Checklist and learn how to convert from site visitors to paying customers. This exclusive checklist will help you to simplify conversion processes and scale your sales.',
          marked: 'Retargeting Funnel Checklist',
        },
        {
          title: 'Ad Design Hacks',
          text:
            'Learn 9 Ad Design Principles on improving your ad creatives from Patrick. Bring your ad design techniques to perfection to convert more leads and watch your conversion rate take off!',
          marked: '9 Ad Design Principles',
        },
        {
          title: 'AIDA Copywriting Template',
          text:
            'One of the key components of any ad is undeniably its copy. Learn how to create powerful and impactful copy by using the AIDA Copywriting Template and create ads that are highly converting.',
          marked: 'AIDA Copywriting Template',
        },
      ],
    },
    feedback: {
      title: 'OUR READERS FEEDBACK',
      members: [
        {
          name: 'Jonas Moll',
          socialLink: 'https://linkedin.com/in/jonas-moll',
          jobTitle: 'Owner of Jonas Moll Social Media Branding',
          text:
            'There was a huge value and a lot of expertise from the first pages only. Especially in a budget optimizing as well as how to do a campaign I learned a lot. It was definitely worth the time!',
        },
        {
          name: 'Nemo Tronnier',
          socialLink: 'https://linkedin.com/in/nemtron',
          jobTitle: 'Co-founder of Social DNA',
          text:
            'Great content and design - this eBook brings a lot value to my team and our B2B customers. A must-read for all who want to improve in social advertising.',
        },
      ],
    },

    readySetGrow: {
      title: 'Ready, Set, Grow! ',
    },

    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    haveRead: 'I have read and accept',
    prepositions: {
      and: 'and',
      the: 'the',
    },
    includeTaxes: '*plus sales taxes',
    isBrandOf: 'is a brand of',
    contactUs: 'Contact Us',
    privacy: 'Privacy Policy',
    imprint: 'Imprint',
    termsConditions: 'Terms and Conditions',
  },
  [avaliableLangs.de]: {
    main: {
      title: 'Die Facebook Bibel: Das Doping für dein Performance-Marketing!',
      desc:
        'Lerne von Forbes 30 under 30 und Facebook-Guru Patrick Wind, wie du Marketingkampagnen erstellst und optimierst, die deinen ROAS steigern.',
    },
    buyButton: {
      text: 'KOSTENLOSER DOWNLOAD',
      taxes: 'Price includes all taxes',
    },
    freebieButton: {
      text: 'KOSTENLOSEN AUSZUG HERUNTERLADEN',
    },
    workedWith: 'KUNDEN VON PATRICK',
    videoDesc: {
      text:
        'ERHALTE EINEN ERSTEN EINDRUCK VON DEM BIBEL-INHALT IN DIESEM VIDEO',
    },

    author: {
      title: 'Über den Autor',
      name: 'Patrick Wind',
      desc: [
        {
          marked: 'Forbes 30 under 30',
          text:
            'Patrick Wind ist ein Forbes 30 under 30, der tausenden Entrepreneurs und Marketern mit seinen Online-Coachings und Workshops half, ihre Produkte deutlich rentabler zu vermarkten.',
        },
        {
          text:
            'Patrick hat in den letzten Jahren für mehr als hundert Brands Werbebudgets von über 10 Millionen Euro verwaltet. Er teilt seine Marketingkenntnisse und Erfahrungen auch als Tutor für verschiedene Business Schools und Universitäten in ganz Europa.',
        },
      ],
    },
    promotion: {
      title: 'Werde ein Facebook Marketing Experte',
      desc:
        'Es ist Zeit, dein Facebook-Marketing-Game auf das nächste Level zu bringen! Schließe dich über 1.000 Personen an, die bereits heute von Patrick Winds Insights profitieren. Es ist an dir, das beste aus mehr als 100 Tipps und Hacks für deine Facebook- & Instagram-Ads zu machen.',
    },

    course: {
      title: 'Nimm an Europas Top-Programm für Facebook & Instagram Ads teil',
      desc: {
        text:
          'Wir zeigen dir im Detail, wie du den Algrorithmus von Facebook und Instagram so für dich einsetzen kannst, dass du jeden Monat hochprofitable Ads schalten kannst, die sogar international skalierbar sind. Mit unserer eLearning-Plattform kannst du jederzeit und von überall auf alle Inhalte zugreifen – sei es per Handy, Tablet oder Desktop.',
        marked: 'jeden Monat hochprofitable Ads schalten kannst',
      },
      saveMoney: 'Mit weCreate kannst du 50€ sparen!',
      moreInfo: 'Mehr Informationen',
      courseLink: 'https://www.adsaccelerator.com/wecreate',
    },

    recipes: {
      title: 'Rezepte für Michelin Star Facebook & Instagram Marketing',
      steps: [
        {
          number: '26',
          text: 'Hacks um die Relevanz deiner Ads zu stärken',
          marked: 'Relevanz deiner Ads',
        },
        {
          number: '10',
          text: 'Gebote des perfekten Ad Designs',
          marked: 'Gebote',
        },
        {
          number: '5',
          text: 'bewährte Skalierungs-Strategien',
          marked: 'Skalierungs-Strategien',
        },
        {
          number: '4',
          text: 'Schritte zur täglichen und wöchentlichen Optimierung',
          marked: 'Optimierung',
        },
      ],
    },

    coach: {
      title: 'Skaliere dein Business mit unserer AdsAccelerator MasterMind',
      desc:
        'Unsere MasterMind ist ein 12-monatiges Coaching-Programm bestehend aus ',
      list: [
        {
          desc:
            'Wöchentliche MasterMind-Gruppen-Calls mit Patrick Wind oder seinen Mentoren',
          marked: 'Wöchentliche MasterMind-Gruppen-Calls',
        },
        {
          desc:
            '3 exklusive 2-Tages-Workshops in Barcelona (Freitag & Samstag)',
          marked: 'exklusive 2-Tages-Workshops',
        },
        { desc: 'Private WhatsApp-Gruppe', marked: 'Private WhatsApp-Gruppe' },
        {
          desc: 'Premium-Zugang zu allen AdsAccelerator-Kursen ',
          marked: 'Premium-Zugang',
        },
      ],
      moreInfo: 'Mehr Informationen',
      coachLink: 'https://www.adsaccelerator.com/mastermind',
    },

    insideReport: {
      title: 'EIN BLICK IN DEN REPORT',
      slides: [
        {
          title: 'Top 50 Facebook Zielgruppen',
          text:
            'Entdecke die Top 50 Facebook-Zielgruppen und lerne, wie du Schritt für Schritt eine Strategie umsetzt, um neue, engagierte Zielgruppen für dein Unternehmen zu erreichen. Diese Zielgruppen sind der Startpunkt für deine A/B-Tests und die Grundlage profitabler Kampagnen.',
        },
        {
          title: 'Funnel Retargeting Checklist',
          text:
            'Fang an die Retargeting-Trichter-Checkliste zu nutzen und erfahre, wie du Website-Besucher in zahlende Kunden konvertierst. Diese hochwertige Checkliste hilft dir dabei, Konvertierungsprozesse zu simplifizieren und deine Verkäufe zu skalieren.',
          marked: 'Retargeting-Trichter-Checkliste',
        },
        {
          title: 'Ad Design Hacks',
          text:
            'Erfahre von Patrick, welche Prinzipien zum Ad-Design zur Verbesserung ihrer Performance beitragen. Optimiere deine Ad-Design-Strategie, um mehr Leads zu gewinne und konvertieren, was deine Conversion-Rate massiv steigern wird!',
        },
        {
          title: 'AIDA Copywriting Template',
          text:
            'Eine der Schlüsselkomponenten jeder Ads ist zweifellos die Copy/der Text. Erfahre hier, wie du mithilfe der AIDA-Vorlage performante und wirkungsvolle Texte erstellen und die Conversion und Performance deine Ads boostest.',
        },
      ],
    },
    feedback: {
      title: 'UNSER LESER-FEEDBACK',
      members: [
        {
          name: 'Jonas Moll',
          socialLink: 'https://linkedin.com/in/jonas-moll',
          jobTitle: 'Eigentümer von Jonas Moll Social Media Branding',
          text:
            'Bereits die ersten Seiten waren voller Mehrwert und Expertise. Besonders von den Artikeln und Beispielen zur Budgetoptimierung und bei der Kampagnenumsetzung habe ich viel gelernt. Es war auf jeden Fall die Zeit wert!',
        },
        {
          name: 'Nemo Tronnier',
          socialLink: 'https://linkedin.com/in/nemtron',
          jobTitle: 'Co-founder bei Social DNA',
          text:
            'Großartiger inhalt und tolles Design - dieses E-book bringt meinem Team und unseren B2B-Kunden viel Value. Ein Muss für alle, die die Performance ihrer Social Ads verbessern wollen.',
        },
      ],
    },

    readySetGrow: {
      title: 'Ready, Set, Grow! ',
    },

    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    haveRead: 'I have read and accept',
    prepositions: {
      and: 'and',
      the: 'the',
    },
    includeTaxes: '*zzgl. MwSt.',
    isBrandOf: 'ist eine Marke von',
    contactUs: 'Kontakt',
    privacy: 'Datenschutz',
    imprint: 'Impressum',
    termsConditions: 'AGB',
  },
})

export default fbBibleStrings
