/* eslint-disable */
import styled from 'styled-components'

export const LayoutWrapper = styled.div`
  ${props => props.theme.flex.column}
  min-height: 100vh;
  margin: 0 auto;
  max-width: 1200px;
  ${props => props.theme.media.lessThan('desktop')`
    max-width: unset;
    width: 95%;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    width: 90%;
    margin-left: 5%;
  `}
`

export const LayoutChildrenWrapper = styled.div`
  margin-bottom: 160px;
`
