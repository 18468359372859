import React from 'react'
import useLocalizedStrings from '@hooks/useLocalizedStrings'
import BibleAuthor from '@containers/BibleCommonContainers/BibleAuthor'
import BiblePageHeader from '@containers/BibleCommonContainers/BiblePageHeader'
import BiblePageMainInfo from '@containers/BibleCommonContainers/MainInfo/BiblePageMainInfo'
import BibleMainInfoContent from '@containers/BibleCommonContainers/MainInfo/BibleMainInfoContent'
import BiblePageWorkHistory from '@containers/BibleCommonContainers/BiblePageWorkHistory'
import BibleRecipes from '@containers/BibleCommonContainers/BibleRecipes'
import BibleReadySetGrow from '@containers/BibleCommonContainers/BibleReadySetGrow'
import BibleFeedback from '@containers/BibleCommonContainers/BibleFeedback'
import BibleInsideReport from '@containers/BibleCommonContainers/BibleInsideReport'
import BiblePromotion from '@containers/BibleCommonContainers/BiblePromotion'
import BibleVideo from '@containers/BibleCommonContainers/BibleVideo'
import BibleReadySetGrowContent from '@containers/BibleCommonContainers/BibleReadySetGrowContent'
import BibleBuyButton from '@containers/BibleCommonContainers/BibleBuyButton'
import GetFreeChapterTopButton from '@containers/BibleCommonContainers/GetFreeChapterTopButton'
import BibleCoachAd from '@containers/BibleCommonContainers/BibleCoachAd'
import BibleCourseAd from '@containers/BibleCommonContainers/BibleCourseAd'

import withChargebee from '../../hocs/withChargebee'
import FacebookBibleFullEbook from '../FreebiesForms/FacebookBibleFullEbookForm'

const FacebookBiblePageContainer = () => {
  const { labelStrings, currentLang } = useLocalizedStrings()

  const getFreeChapterBtnProps = {
    currentLang,
    buttonText: labelStrings.buyButton.text,
  }
  const idForScroll = 'targetFormForScroll'

  // render page elements
  return (
    <>
      <BiblePageHeader>
        <GetFreeChapterTopButton
          isHeader
          idForScroll={idForScroll}
          {...getFreeChapterBtnProps}
        />
      </BiblePageHeader>

      <BiblePageMainInfo
        title={labelStrings.main.title}
        posterImgSrc='../../fbible/CapricornCombined@2x.png'
      >
        <BibleMainInfoContent text={labelStrings.main.desc}>
          <FacebookBibleFullEbook
            idForScroll={idForScroll}
            {...getFreeChapterBtnProps}
          />
        </BibleMainInfoContent>
      </BiblePageMainInfo>

      <BiblePageWorkHistory
        title={labelStrings.workedWith}
        imgSrcs={{
          companies: '../../fbible/Companies_group@2x.png',
          institutions: '../../fbible/Institutions@2x.png',
        }}
      />
      <BibleVideo videoId='SsMwEM4bgJ8' videoDesc={labelStrings.videoDesc} />

      <BibleCourseAd
        course={labelStrings.course}
        posterSrc='../../fbible/PatrickCourse.png'
        guaranteeSrc='../../fbible/PatrickGuarantee.png'
        currentLang={currentLang}
      />

      <BibleAuthor
        author={labelStrings.author}
        posterSrc='../../fbible/Patrick@2x.png'
        withCerts
        certLogos={[
          '../../fbible/fbCertified.png',
          '../../fbible/fbCertifiedSecond.png',
        ]}
      />

      <BiblePromotion
        promotion={labelStrings.promotion}
        posterSrc='../../fbible/Ipad@2x.png'
      />

      <BibleCoachAd
        coach={labelStrings.coach}
        posterSrc='../../fbible/PatrickMastermind.png'
        currentLang={currentLang}
      />

      <BibleRecipes recipes={labelStrings.recipes} />
      <BibleFeedback
        title={labelStrings.feedback.title}
        socialSrc='../../social/LinkedIn.svg'
        feedbacksMap={[
          {
            src: '../../fbible/Jonas@2x.png',
            member: labelStrings.feedback.members[0],
          },
          {
            src: '../../fbible/Sebastian@2x.png',
            member: labelStrings.feedback.members[1],
          },
          {
            src: '../../fbible/Nemo@2x.png',
            member: labelStrings.feedback.members[2],
          },
        ]}
      />
      <BibleInsideReport
        insideReport={labelStrings.insideReport}
        slides={[
          // positions of slides in array matters, srcs are mapped with corresponding texts
          '../../fbible/FB_top-50-facebook-audiences@2x.png',
          '../../fbible/FB_Page-RetargetingFunnel@2x.png',
          '../../fbible/FB_Page-topAdDesignHacks@2x.png',
          '../../fbible/FB_Page_Copywriting@2x.png',
        ]}
      />
      <BibleReadySetGrow
        posterSrc='../../fbible/footer@2x.png'
        title={labelStrings.readySetGrow.title}
      >
        <BibleReadySetGrowContent>
          <GetFreeChapterTopButton
            isHeader
            idForScroll={idForScroll}
            {...getFreeChapterBtnProps}
          />
        </BibleReadySetGrowContent>
      </BibleReadySetGrow>
    </>
  )
}

export default withChargebee()(FacebookBiblePageContainer)
