/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BuyButton from '@common/buttons/BuyButton'

const BuyContainerWrapper = styled.div`
  width: fit-content;
  ${props => props.theme.flex.column}
  justify-content: space-between;
  position: relative;
  ${props =>
    props.currentLang === 'de' &&
    `& button {
      font-size: 19px;
    }
  `}
  ${props => props.theme.media.lessThan('mobile')`
    & button {
      font-size: ${props.currentLang === 'de' ? 12 : 14}px;
    }
  `}
`

const GetFreeChapterTopButton = ({
  isHeader,
  buttonText,
  currentLang,
  idForScroll,
}) => {
  const clickHandler = e => {
    e.preventDefault()
    if (idForScroll) {
      const element = document.getElementById(idForScroll)
      const isMobile = window.innerWidth <= 780
      window.scrollTo({
        top: isMobile ? element.offsetTop * 1.5 : element.offsetTop / 1.5,
        behavior: 'smooth',
      })
    }
  }

  return (
    <BuyContainerWrapper isHeader={isHeader} currentLang={currentLang}>
      <BuyButton
        text={buttonText}
        isHeader={isHeader}
        currentLang={currentLang}
        onClick={clickHandler}
      />
    </BuyContainerWrapper>
  )
}

GetFreeChapterTopButton.propTypes = {
  isHeader: PropTypes.bool,
  buttonText: PropTypes.string,
  currentLang: PropTypes.string,
  idForScroll: PropTypes.string,
}

GetFreeChapterTopButton.defaultProps = {
  isHeader: false,
  buttonText: '',
  currentLang: 'en',
  idForScroll: '',
}

export default GetFreeChapterTopButton
