/* eslint-disable import/prefer-default-export */
import { createGlobalStyle } from 'styled-components'
import { generateMedia } from 'styled-media-query'

const media = generateMedia({
  desktop: '1200px',
  tablet: '768px',
  mobile: '480px',
})

const generalTheme = {
  media,
  color: {
    white: '#ffffff',
    black: '#000000',
    white50: '#ffffff50',
    white90: '#ffffff90',
    orange: '#e84a31',
    orange50: '#e84a3150',
    orange80: '#e84a3180',
    aqua: '#55ffff',
    aqua50: '#55ffff50',
    blue: '#2853ff',
    tinyLightBlue: '#4978FF',
    lightBlue: '#0053FF',
    lightGrey: '#6b7079',
    grey: '#979797',
    darkGrey: '#3e3f42',
    yellow: '#FEED6E',
    razmataz: '#FB1053',
    razmataz80: '#FB105380',
  },
  fontFamily: {
    roboto: "'Roboto', sans-serif",
    poppins: "'Poppins', sans-serif",
  },
  fontSizes: {
    size11: '11px',
    size12: '12px',
    size14: '14px',
    size16: '16px',
    size18: '18px',
    size20: '20px',
    size22: '22px',
    size24: '24px',
    size25: '25px',
    size28: '28px',
    size38: '38px',
    size40: '40px',
    size44: '44px',
    size46: '46px',
    size50: '50px',
    size54: '54px',
    size80: '80px',
    size116: '116px',
  },
  fontWeight: {
    w300: '300',
    w500: '500',
    w800: '800',
    bold: '900',
  },

  flex: {
    display: 'display: flex;',
    centerAll: `
      display: flex;
      justify-content: center;
      align-items: center;
      `,
    column: `
    display: flex;
    flex-direction: column;
    `,
  },
}

// themes with differences
export const bibleTheme = {
  ...generalTheme,
  buttonBGColor: generalTheme.color.orange,
  buttonBGActiveColor: generalTheme.color.orange80,
  mainSectionMarkedHeaderColor: generalTheme.color.aqua,
  authorBGColor: generalTheme.color.lightBlue,
  promotionBGColor: generalTheme.color.yellow,
  recipesMarkedHeaderBGColor: generalTheme.color.aqua,
  recipesMarkedHeaderColor: generalTheme.color.black,
  punksBorderColor: generalTheme.color.aqua,
  feedbackBGcolor: generalTheme.color.aqua,
  feedbackImgShadow: generalTheme.color.blue,
  freebieRadioColor: generalTheme.color.blue,
  freebieCheckboxBorderColor: generalTheme.color.lightBlue,
  freebieCheckboxLinksColor: generalTheme.color.lightBlue,
}

export const tikTokTheme = {
  ...generalTheme,
  buttonBGColor: generalTheme.color.razmataz,
  buttonBGActiveColor: generalTheme.color.razmataz80,
  mainSectionMarkedHeaderColor: generalTheme.color.aqua,
  authorBGColor: generalTheme.color.razmataz,
  promotionBGColor: generalTheme.color.aqua,
  recipesMarkedHeaderBGColor: generalTheme.color.razmataz,
  recipesMarkedHeaderColor: generalTheme.color.white,
  punksBorderColor: generalTheme.color.yellow,
  feedbackBGcolor: generalTheme.color.yellow,
  feedbackImgShadow: generalTheme.color.aqua,
  freebieRadioColor: generalTheme.color.razmataz,
  freebieCheckboxBorderColor: generalTheme.color.black,
  freebieCheckboxLinksColor: generalTheme.color.razmataz,
}

export const GlobalPunksStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700&display=swap');
  & > * {
    font-family: ${generalTheme.fontFamily.roboto}
  }
`
