/* eslint-disable */
import React, { useEffect } from 'react'

const withChargebee = (params = {}) => Component => {
  // const { coupon } = params
  const Wrapper = ({ componentProps }) => {
    useEffect(() => {
      if (Chargebee) {
        Chargebee.registerAgain()
        //
        // unused, can be probably used in futuer for coupons
        // if (coupon) {
        // const chargebee = Chargebee.getInstance()
        // const links = document.querySelectorAll('[data-cb-type=checkout]')
        // for (const link of links) {
        //   const product = chargebee.getProduct(link)
        // product.addCoupon(coupon)
        // }
        // }
      }
    }, [])
    return <Component {...componentProps} />
  }
  return Wrapper
}

export default withChargebee
