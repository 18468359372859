import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import DoubleColumnsContainer from '@containers/DoubleColumnsContainer'
import CustomizableSlider from '@common/sliders/CustomizableSlider'
import SectionTitleText from '@common/SectionTitleText'
import Poster from '@common/Poster'
import { MarkedHeader } from '@common/MarkedHeader'
import createSymbolBackgroundStyle from '@common/backgrounds/createSymbolBackgroundStyle'

const MainWrapper = styled.div`
  width: 94%;
  margin: 130px auto 140px auto;
  & > h1 {
    margin-bottom: 30px;
  }
  position: relative;
  &::before {
    ${createSymbolBackgroundStyle({
      width: '70%',
      height: '50%',
      right: '-5%',
      top: '20%',
      zIndex: 0,
    })}
  }
  ${props => props.theme.media.lessThan('990px')`
      &::before {
        width: 100vw;
        left: -3%;
      }
  `}
  ${props => props.theme.media.lessThan('mobile')`
    margin: 130px auto 0px auto;
  `}
`

const SliderBackgroundWrapper = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    height: 447px;
    width: 100%;
    display: block;
    z-index: -1;
    top: 16%;
    left: 0;
    background: ${props => props.theme.color.aqua};
    ${props => props.theme.media.lessThan('990px')`
      width: 120%;
      left: -10%;
      height: 90%;
    `}
    ${props => props.theme.media.lessThan('mobile')`
      height: 575px;
    `}
    ${props => props.theme.media.between('390px', '420px')`
      height: 620px;
    `}
    ${props => props.theme.media.lessThan('390px')`
      height: 650px;
    `}
  }
`

const SlideContentWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  flex-direction: column;
  align-items: start;
  height: 44em;
  padding-left: 10%;
  width: 98%;
  ${props => props.theme.media.lessThan('990px')`
    margin-top: 50px;
    height: 70%;
  `}
`

const SlideHeaderWrapper = styled(MarkedHeader)`
  font-size: ${props => props.theme.fontSizes.size38};
  line-height: 48px;
  text-shadow: none;
  background-color: ${props => props.theme.color.white};
  box-shadow: 0px 0 0 ${props => props.theme.color.white},
    8px 0 0 ${props => props.theme.color.white};

  padding-left: 8px;
  padding-right: 4px;

  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size28}
    line-height: unset;
    padding-left: 8px;
    padding-right: 4px;
  `}
`

const SlideHeaderWrapperDiv = styled.div`
  line-height: 52px;

  ${props => props.theme.media.lessThan('mobile')`
      line-height: 40px;
      margin-top: 24px;
    `}
`

const SlideHeaderNumberWrapper = styled(SlideHeaderWrapper)`
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.black};
  box-shadow: none;
  padding-left: 16px;
  padding-right: 10px;

  ${props => props.theme.media.lessThan('mobile')`
    padding-left: 12px;
    padding-right: 8px;
  `}
`

const SlideTextWrapper = styled.div`
  font-size: ${props => props.theme.fontSizes.size20};
  background: ${props => props.theme.color.aqua};
  line-height: 25px;
  max-width: 450px;
  margin-top: 30px;
  ${props => props.theme.media.lessThan('mobile')`
    line-height: 22px;
    font-size: ${props.theme.fontSizes.size16}
    max-width: 340px;
  `}
  ${props => props.theme.media.lessThan('990px')`
    max-width: 80%;
  `}
`

const CustomArrowWrapper = styled.button`
  position: absolute;
  font-size: 85px;
  top: 42%;
  color: ${props => props.theme.color.darkGrey};
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
  }
  ${props => props.theme.media.lessThan('650px')`
    top: 25%;
  `}
`

const CustomLeftArrowWrapper = styled(CustomArrowWrapper)`
  left: 1%;
`
const CustomRightArrowWrapper = styled(CustomArrowWrapper)`
  left: 96%;
  ${props => props.theme.media.lessThan('500px')`
    left: 93%;
  `}
`
const PosterWrapper = styled.div`
  height: 590px;
  width: 413px;
  margin: 0 auto;
  ${props => props.theme.media.between('990px', '1100px')`
    height: 540px;
    width: 370px;
  `}
  ${props => props.theme.media.between('650px', '990px')`
    height: 616px;
    width: 437px;
  `}
  ${props => props.theme.media.between('mobile', '550px')`
    height: 370px;
    width: 260px;
  `}
  ${props => props.theme.media.between('350px', 'mobile')`
    height: 320px;
    width: 230px;
  `}
  ${props => props.theme.media.lessThan('350px')`
    height: 290px;
    width: 200px;
  `}
`
const extraPosterStyles = `
  margin: 0 auto;
`
const titleExtraStyles = `
  text-shadow: none;
`

// custom slider arrows
const createCustomArrow = Component => ({ onClick }) => (
  <Component onClick={() => onClick()} />
)

const CustomLeftArrow = createCustomArrow(({ onClick }) => (
  <CustomLeftArrowWrapper onClick={onClick}>&#x2039;</CustomLeftArrowWrapper>
))

const CustomRightArrow = createCustomArrow(({ onClick }) => (
  <CustomRightArrowWrapper onClick={onClick}>&#x203A;</CustomRightArrowWrapper>
))

// slider
const BibleCustomSlider = ({ slidesWithSrc, title }) => {
  const slidesToRender = slidesWithSrc.map((el, index) => {
    return (
      <DoubleColumnsContainer reverseOnMobile key={el.slide.title}>
        <SlideContentWrapper>
          <SlideHeaderWrapperDiv>
            <SlideHeaderNumberWrapper>{index + 1}.</SlideHeaderNumberWrapper>
            <SlideHeaderWrapper>{el.slide.title}</SlideHeaderWrapper>
          </SlideHeaderWrapperDiv>

          <SlideTextWrapper>{el.slide.text}</SlideTextWrapper>
        </SlideContentWrapper>
        <PosterWrapper>
          <Poster imgSrc={el.src} extraStyles={extraPosterStyles} />
        </PosterWrapper>
      </DoubleColumnsContainer>
    )
  })

  return (
    <MainWrapper>
      <SectionTitleText text={title} extraStyles={titleExtraStyles} />
      <SliderBackgroundWrapper>
        <CustomizableSlider
          slidesToRender={slidesToRender}
          toRightArrow={<CustomRightArrow />}
          toLeftArrow={<CustomLeftArrow />}
        />
      </SliderBackgroundWrapper>
    </MainWrapper>
  )
}

BibleCustomSlider.propTypes = {
  slidesWithSrc: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
}

BibleCustomSlider.defaultProps = {
  title: '',
}

export default BibleCustomSlider
