/* eslint-disable import/prefer-default-export */
import React from 'react'

export const createStrWithMarkedPart = (
  str,
  markedSubStr,
  Component = ({ children }) => <span>{children}</span>,
) => {
  const splittedStr = str.split(markedSubStr)
  return (
    <span>
      {splittedStr[0] ? splittedStr[0] : ''}{' '}
      <Component>{markedSubStr}</Component>{' '}
      {splittedStr[1] ? splittedStr[1] : ''}
    </span>
  )
}

export const createStrWithMarkedUpperCasedPart = (str, markedSubStr) => {
  const splittedStr = str.split(markedSubStr)
  return (
    <span>
      {splittedStr[0] ? splittedStr[0] : ''} <b>{markedSubStr.toUpperCase()}</b>{' '}
      {splittedStr[1] ? splittedStr[1] : ''}
    </span>
  )
}

export const detectBrowser = browserName =>
  navigator &&
  navigator.userAgent.toLowerCase().includes(browserName.toLowerCase())
