const createSymbolBackgroundStyle = ({
  symbol = '+',
  symbolColor = 'black',
  symbolSize = 16,
  svgHeight = '40px',
  svgWidth = '40px',
  height = '200px',
  width = '200px',
  top = 'unset',
  left = 'unset',
  right = 'unset',
  bottom = 'unset',
  zIndex = -1,
} = {}) => `
    position: absolute;
    content: '';
    display: block;
    opacity: 0.25;
    top: ${top};
    left: ${left};
    bottom: ${bottom};
    right: ${right};
    z-index: ${zIndex};
    height: ${height};
    width: ${width};
    background-repeat: repeat;
    background-image: url("data:image/svg+xml;utf8,
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1'
        height='${svgHeight}' width='${svgWidth}'
        >
      <text x='0' y='15' fill='${symbolColor}' font-size='${symbolSize}'>${symbol}</text>
    </svg>");
  `

export default createSymbolBackgroundStyle
