/* eslint-disable */
import styled from 'styled-components'

export const FormWrapper = styled.div`
  margin: 30px 0 0 auto;
  width: ${props => (props.isFullWidth ? 100 : 85)}%;
  background: ${props => props.theme.color.white};
  ${props => props.theme.media.lessThan('mobile')`
    width: 100%;
  `}
`

export const ParagraphCommonStyles = styled.p`
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.fontFamily.roboto};
`

export const FormHeaderWrapper = styled(ParagraphCommonStyles)`
  margin-bottom: 10px;
  font-size: ${props => props.theme.fontSizes.size24};
  font-weight: ${props => props.theme.fontWeight.w300};
  line-height: 28px;
  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size16};
    line-height: 22px;
  `}
`

export const FormInputOuterWrapper = styled.div`
  margin-bottom: 20px;
`

export const FormInputWrapper = styled.input`
  width: 100%;
  border: 1px solid ${props => props.theme.color.grey};
  height: 40px;
  font-size: ${props => props.theme.fontSizes.size16};
  font-weight: ${props => props.theme.fontWeight.w300};
  padding-left: 10px;
  line-height: 22px;
`

export const LangSelectOuterWrapper = styled.div`
  ${props => props.theme.flex.display};
`
export const SelectLangWrapper = styled.div`
  position: relative;
  ${props => props.theme.flex.centerAll};
  & span {
    margin: 0 20px 0 5px;
    font-size: ${props => props.theme.fontSizes.size14};
    line-height: 13px;
  }
  ${props => props.theme.media.lessThan('mobile')`
    & span {
      font-size: ${props.theme.fontSizes.size12};
    }
  `}
`

export const WhoAmIOuterWrapper = styled(LangSelectOuterWrapper)`
  flex-direction: column;
  align-items: flex-start;
`

export const SelectRoleWrapper = styled(SelectLangWrapper)`
  margin: 5px 0;
  justify-content: flex-start;
`

export const SelectLangRadioLabelWrapper = styled.label`
  height: 26px;
  width: 26px;
  position: relative;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.color.grey};
  ${props => props.theme.media.lessThan('mobile')`
    height: 20px;
    width: 20px;
  `}
`

export const SelectRoleRadioLabelWrapper = styled.span`
  ${props => props.theme.media.lessThan('desktop')`
    max-width: 79%;
  `}
`

export const SelectLangRadioWrapper = styled.input`
  z-index: -1;
  position: absolute;
  left: 6px;
  &:checked + label::before {
    position: absolute;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    top: 6px;
    left: 6px;
    border-radius: 50%;
    border: none;
    background: ${props => props.theme.freebieRadioColor};
  }
  ${props => props.theme.media.lessThan('mobile')`
    left: 3px;
    &:checked + label::before {
      top: 3px;
      left: 3px;
    }
  `}
`
export const SelectLangGroupWrapper = styled.div`
  width: fit-content;
`

export const LangSelectHeaderWrapper = styled(ParagraphCommonStyles)`
  font-size: ${props => props.theme.fontSizes.size14};
  line-height: 13px;
  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size12};
  `}
`

export const SignCheckboxOuterWrapper = styled.div`
  margin: 15px 0;
  position: relative;
  ${props => props.theme.flex.display}
`
export const SignCheckboxLabelWrapper = styled.label`
  height: 24px;
  width: 24px;
  position: relative;
  margin-right: 10px;
  display: block;
  border-radius: 2px;
  border: 2px solid ${props => props.theme.freebieCheckboxBorderColor};

  ${props => props.theme.media.lessThan('mobile')`
    height: 20px;
    width: 20px;
  `}
`
export const SignCheckboxWrapper = styled.input`
  z-index: -1;
  position: absolute;
  top: 4px;
  left: 4px;
  &:checked + label::before {
    height: inherit;
    width: inherit;
    position: absolute;
    top: ${props => props.checkTopPos || '-46%'};
    left: 0;
    content: '✓';
    font-size: 25px;
    color: ${props => props.theme.freebieRadioColor};
  }
  ${props => props.theme.media.lessThan('mobile')`
    top: 3px;
    left: 3px;
      &:checked + label::before {
        font-size: 20px;
        ${props.checkTopPos &&
          `
          top: -40%;
        `}
      }
  `}
`

export const SignCheckboxTextWrapper = styled.div`
  font-size: ${props => props.theme.fontSizes.size14};
  width: 90%;
  line-height: 16px;
  position: relative;
  & a {
    color: ${props => props.theme.freebieCheckboxLinksColor};
  }
  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size12};
    line-height: 13px;
  `}
`
