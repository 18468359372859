/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import Logo from '@common/Logo'
import styled from 'styled-components'
// import { Link } from 'gatsby'
import { Row, Col } from 'antd'
import useLocalizedStrings from '@hooks/useLocalizedStrings'

const FooterWrapper = styled.div`
  height: 110px;
  background: ${props => props.theme.color.aqua};
  padding-top: 35px;
  width: 100vw;
  position: absolute;
  left: 0;
  bottom: 0;
  padding-left: 1%;
  ${props => props.theme.media.lessThan('990px')`
    padding-top: 17px;
  `}
  ${props => props.theme.media.lessThan('tablet')`
    padding-top: 3%;
  `}
`

const ContactUsWrapper = styled.div`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSizes.size16};
  line-height: 40px;
  text-align: center;
  & > a {
    color: ${props => props.theme.color.black};
  }
`

const IconsWrapper = styled.div`
  display: flex;
  width: 150px;
  justify-content: space-between;
  margin-left: 10%;
  cursor: pointer;
  color: ${props => props.theme.color.black} !important;
`

const TextWrapper = styled.div`
  display: flex;
  font-size: 16px;
  justify-content: space-around;
  max-width: 790px;
  margin: 0 auto;
  @media (max-width: 1050px) {
    font-size: 14px;
    flex-direction: column;
    align-items: left;
    margin-left: 5%;
  }
  @media (max-width: 800px) {
    font-size: 11px;
  }

  & span a {
    color: ${props => props.theme.color.black};
  }
  & span a:hover {
    text-decoration: underline;
  }
`

const InculudeTaxesWrapper = styled.div`
  position: absolute;
  right: 45%;
  top: 10%;
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: 16px;
  ${props => props.theme.media.lessThan('1050px')`
    right: 45%;
    top: 0%;
    font-size: 14px;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    right: 41%;
    top: -1%;
  `}
`

const socialIconsPaths = [
  {
    name: 'Facebook',
    src: 'https://www.facebook.com/OnlinePunks-107548027292271/',
  },
  {
    name: 'Youtube',
    src: 'https://www.youtube.com/channel/UCl4BRUbFKQTt-NAlqd9XE2g',
  },
  { name: 'Insta', src: 'https://www.instagram.com/onlinepunkss/' },
  { name: 'LinkedIn', src: 'https://www.linkedin.com/company/14833172/' },
  { name: 'Tiktok', src: 'https://www.tiktok.com/@onlinepunkss' },
]

const FooterContainer = ({ showTaxesNote, location }) => {
  const { labelStrings, currentLang } = useLocalizedStrings()

  const pathnameArr = location.pathname.split('/').filter(str => !!str)
  const socialIconsPrefixPath =
    pathnameArr.length === 0 ? '' : '../'.repeat(pathnameArr.length)
  return (
    <FooterWrapper>
      <Row type='flex' justify='space-around' align='middle'>
        <Col
          xs={{ span: 12, order: 1 }}
          sm={{ span: 12, order: 1 }}
          md={{ span: 12, order: 1 }}
          lg={{ span: 4, order: 1 }}
          xl={{ span: 4, order: 1 }}
        >
          <IconsWrapper>
            {socialIconsPaths.map(path => {
              return (
                <a
                  href={path.src}
                  target='_blank'
                  rel='noopener noreferrer'
                  key={path.name}
                >
                  <Logo
                    imgSrc={`${socialIconsPrefixPath}social/${path.name}.svg`}
                    height={26}
                    width={26}
                    key={path.name}
                  />
                </a>
              )
            })}
          </IconsWrapper>
        </Col>

        <Col
          xs={{ span: 24, order: 3 }}
          sm={{ span: 24, order: 3 }}
          md={{ span: 24, order: 3 }}
          lg={{ span: 16, order: 2 }}
          xl={{ span: 16, order: 2 }}
        >
          <TextWrapper>
            <span>© 2020 // weCreate {labelStrings.isBrandOf} OnlinePunks</span>
            <span>
              <a href='/imprint' target='_blank'>
                {labelStrings.imprint}
              </a>{' '}
              {'//'}{' '}
              <a href={`/${currentLang}/privacy-policy`} target='_blank'>
                {labelStrings.privacy}
              </a>{' '}
              {'//'}{' '}
              <a href='/termsandconditions' target='_blank'>
                {labelStrings.termsConditions}
              </a>
            </span>
          </TextWrapper>
        </Col>

        <Col
          xs={{ span: 12, order: 2 }}
          sm={{ span: 12, order: 2 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 4, order: 3 }}
          xl={{ span: 4, order: 3 }}
        >
          <ContactUsWrapper>
            <a
              href='https://www.onlinepunks.com/contact'
              target='_blank'
              rel='noopener noreferrer'
            >
              {labelStrings.contactUs}
            </a>
          </ContactUsWrapper>
        </Col>
      </Row>
      {showTaxesNote && labelStrings.includeTaxes && (
        <InculudeTaxesWrapper>{labelStrings.includeTaxes}</InculudeTaxesWrapper>
      )}
    </FooterWrapper>
  )
}

FooterContainer.propTypes = {
  showTaxesNote: PropTypes.bool,
  location: PropTypes.object,
}

FooterContainer.defaultProps = {
  showTaxesNote: false,
  location: {},
}

export default FooterContainer
