import styled from 'styled-components'

const DefaultButton = styled.button`
  outline: none;
  height: 50px;
  width: 50px;
  background: transparent;
  border: none;
  text-align: center;
  ${props => props.theme.flex.centerAll};
  display: -webkit-inline-box;
  text-transform: uppercase;
  &:active,
  &:focus {
    outline: none;
  }
`

export default DefaultButton
