/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SectionTitleText from '@common/SectionTitleText'
import Poster from '@common/Poster'
import DoubleColumnsContainer from '@containers/DoubleColumnsContainer'

const MainWrapper = styled.div`
  width: 90%;
  margin: 110px auto 40px auto;
  ${props => props.mainExtraStyles}
  ${props => props.theme.media.lessThan('mobile')`
    margin: 70px auto 40px auto;
`}
`
const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 70px 0 70px 30px;
  height: 100%;
  ${props => props.theme.flex.column}
  ${props => props.contentExtraStyles}
  ${props => props.theme.media.lessThan('990px')`
    padding: 70px 0 70px 30px;
  `}
  ${props => props.theme.media.lessThan('640px')`
    padding: 70px 0 0 0;
    justify-content: center;
    align-items: center;
    & h1 {
      width: 290px;
    }
  `}
`

const titleExtraStyles = `
  text-transform: uppercase;
  text-shadow: 3px 3px #01ffff;
`

const PosterWrapper = styled.div`
  height: 405px;
  width: 502px;
  margin: 0 auto;
  ${props => props.theme.media.between('mobile', '1100px')`
    height: 355px;
    width: 440px;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    height: 210px;
    width: 260px;
  `}
`

const TitleWrapper = styled.div`
  ${props =>
    props.twoLineTitle &&
    `
      max-width: ${props.currentLang === 'de' ? '100%' : '270px'};
      & > h1 {
        width: unset;
      }
    `}
`

const BibleReadySetGrow = ({
  posterSrc,
  title,
  children,
  contentExtraStyles,
  mainExtraStyles,
  twoLineTitle,
  currentLang,
}) => {
  return (
    <MainWrapper mainExtraStyles={mainExtraStyles}>
      <DoubleColumnsContainer>
        <PosterWrapper>
          {posterSrc && <Poster imgSrc={posterSrc} />}
        </PosterWrapper>

        <ContentWrapper contentExtraStyles={contentExtraStyles}>
          {title && (
            <TitleWrapper twoLineTitle={twoLineTitle} currentLang={currentLang}>
              <SectionTitleText text={title} extraStyles={titleExtraStyles} />
            </TitleWrapper>
          )}
          {children}
        </ContentWrapper>
      </DoubleColumnsContainer>
    </MainWrapper>
  )
}

BibleReadySetGrow.propTypes = {
  posterSrc: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  contentExtraStyles: PropTypes.string,
  mainExtraStyles: PropTypes.string,
  twoLineTitle: PropTypes.bool,
  currentLang: PropTypes.string,
}

BibleReadySetGrow.defaultProps = {
  posterSrc: '',
  title: '',
  children: <></>,
  contentExtraStyles: '',
  mainExtraStyles: '',
  twoLineTitle: false,
  currentLang: 'en',
}

export default BibleReadySetGrow
