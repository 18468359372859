/* eslint-disable */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BuyButton from '@common/buttons/BuyButton'

const BuyContainerWrapper = styled.div`
  width: fit-content;
  ${props => props.theme.flex.column}
  justify-content: space-between;
  position: relative;
`

const BibleBuyButton = ({
  isHeader,
  buttonText,
  currentLang,
  cbType,
  cbPlanId,
  addonsId0,
  disableAllActions,
}) => {
  const buyClickHandler = e => {
    e.preventDefault()
    fbq('track', 'InitiateCheckout')
  }

  const showNoActionsButton = () => (
    <BuyButton
      text={buttonText}
      isHeader={isHeader}
      currentLang={currentLang}
      isInactive={disableAllActions}
    />
  )

  const showButtonWithActions = () => (
    <a
      onClick={buyClickHandler}
      data-cb-type={cbType}
      data-cb-plan-id={cbPlanId}
      data-cb-addons_id_0={addonsId0}
    >
      {showNoActionsButton()}
    </a>
  )

  return (
    <BuyContainerWrapper isHeader={isHeader}>
      {disableAllActions ? showNoActionsButton() : showButtonWithActions()}
    </BuyContainerWrapper>
  )
}

BibleBuyButton.propTypes = {
  isHeader: PropTypes.bool,
  buttonText: PropTypes.string,
  currentLang: PropTypes.string,
  cbType: PropTypes.string,
  cbPlanId: PropTypes.string,
  addonsId0: PropTypes.string.isRequired,
  disableAllActions: PropTypes.bool,
  coupon: PropTypes.string,
}

BibleBuyButton.defaultProps = {
  isHeader: false,
  buttonText: '',
  currentLang: 'en',
  cbType: 'checkout',
  cbPlanId: 'onlinepunks-base',
  disableAllActions: false,
  coupon: '',
}

export default BibleBuyButton
