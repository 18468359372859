import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PaymentsIconsGroup from '@common/icons/PaymentsIconsGroup'

const MainInfoSectionWrapper = styled.div`
  width: 85%;
  line-height: 28px;
  font-size: ${props => props.theme.fontSizes.size24};
  font-weight: ${props => props.theme.fontWeight.w300};
  margin: 0 0 0 auto;
  padding-right: 25px;
  ${props => props.theme.media.lessThan('mobile')`
    width: 100%;
    min-width: unset;
    padding-right: 10px;
  `}
`
const TextInnerWrapper = styled.div`
  margin: 40px 0 40px auto;
  background: ${props => props.theme.color.white};
  ${props => props.theme.media.between('mobile', 'tablet')`
    margin: 40px 0 40px 0;
    `}
  ${props =>
    props.theme.media.lessThan('mobile')`	
      width: 265px;	
      font-size: 16px;		
      line-height: 22px;
`};
`

const TextParagraphWrapper = styled.p`
  margin: 0;
`

const PromoParagraphWrapper = styled(TextParagraphWrapper)`
  margin-top: 5px;
  color: ${props => props.theme.buttonBGColor};
`

const BibleMainInfoContent = ({
  text,
  isLandingPage,
  children,
  withPromo,
  promoText,
}) => {
  return (
    <MainInfoSectionWrapper>
      <TextInnerWrapper>
        <TextParagraphWrapper>{text}</TextParagraphWrapper>
        {withPromo && (
          <PromoParagraphWrapper>{promoText}</PromoParagraphWrapper>
        )}
      </TextInnerWrapper>
      {
        //<PaymentsIconsGroup isLandingPage={isLandingPage} />
      }
      {children}
    </MainInfoSectionWrapper>
  )
}

BibleMainInfoContent.propTypes = {
  text: PropTypes.string,
  isLandingPage: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  withPromo: PropTypes.bool,
  promoText: PropTypes.string,
}

BibleMainInfoContent.defaultProps = {
  text: '',
  isLandingPage: false,
  children: <></>,
  withPromo: false,
  promoText: '',
}

export default BibleMainInfoContent
