import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SectionTitleText from '@common/SectionTitleText'

import Poster from '@common/Poster'
import DoubleColumnsContainer from '@containers/DoubleColumnsContainer'
import createSymbolBackgroundStyle from '@common/backgrounds/createSymbolBackgroundStyle'
import BuyButton from '@common/buttons/BuyButton'
import avaliableLangs from '@localization/avaliableLangs'

const MainWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  margin-top: 85px;
  position: relative;
  &::before {
    ${createSymbolBackgroundStyle({
      width: '62%',
      height: '77%',
      top: '15%',
      right: '-5%',
    })}
  }
  ${props => props.theme.media.lessThan('1100px')`
    margin-top: 20px;
  `}

  ${props => props.theme.media.lessThan('990px')`
    &::before {
      width: 100vw;
      height: 60%;
    }
  `}
  ${props => props.theme.media.lessThan('mobile')`
    margin-top: 10px;
    margin-bottom: 60px;
    &::before {
      height: 80%;
      right: -14%;
    }
  `}
`
const ContentWrapper = styled.div`
  box-sizing: border-box;
  // padding: 70px 0 70px 30px;

  height: 100%;
  ${props => props.theme.flex.column}
  align-items: center;
  justify-content: space-between;
  & h1 {
    width: ${props => props.titleStartWidth || 100}%;
    margin: 0 auto;
  }
  ${props => props.theme.media.lessThan('1150px')`
    & h1 {
      width: 90%;
    }
  `}
  ${props => props.theme.media.between('990px', '1150px')`
    & h1 {
      font-size: 40px;
    }
  `}
  ${props => props.theme.media.lessThan('990px')`
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    & h1 {
      width: 100%;
      // text-align: center;
    }
  `}
`

const titleExtraStyles = `
  text-transform: uppercase;
  width: 80%;
  text-shadow: none;
  & > span {
   background: white; 
  }
  
`

const PromotionTextWrapper = styled.div`
  min-height: 236px;
  max-width: 650px;
  background: ${props => props.theme.color.lightBlue};
  color: ${props => props.theme.color.white};
  font-size: ${props => props.theme.fontSizes.size24};
  line-height: 28px;
  box-sizing: border-box;
  padding: 25px 0px 25px 50px;
  margin: 10px 0 2% 10px;
  ${props => props.theme.media.lessThan('desktop')`
    padding: 10px 0px 2px 30px;
  `}
  ${props => props.theme.media.lessThan('550px')`
    padding: 25px 0px 25px 30px;
  `}

  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size16}
    min-height: 50px;
    max-width: 100%;
    line-height: 22px;
    margin: 10px 0;
    padding: 25px 0px 25px 30px;
  `}
`

const PosterWrapper = styled.div`
  margin: 0 auto;
  height: 638px;
  width: 520px;
  ${props => props.theme.media.lessThan('1150px')`
      height: 549px;
      width: 450px;
  `}
  ${props => props.theme.media.between('mobile', 'tablet')`
    height: 524px;
    width: 430px;
  `}
  ${props => props.theme.media.lessThan('mobile')`
      height: 317px;
      width: 260px;
  `}
`

const DescTitleText = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
`

const DescList = styled.ol`
  list-style: none;
  margin: 20px;
  ${props => props.theme.media.lessThan('desktop')`
    margin: 10px;
  `}
`

const DescListItem = styled.li`
  margin: 5px 0px 5px 17px;
  font-size: ${props => props.theme.fontSizes.size20};
  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size16}
  `}
`

const LinkWrapper = styled.a`
  display: block;
  align-self: flex-start;
  margin-left: 10%;
  ${props => props.theme.media.lessThan('990px')`
    align-self: center;
    margin-left: 0;
  `}
`

const BibleCoachAd = ({ coach, posterSrc, currentLang }) => {
  const renderListText = ({ desc, marked }) => {
    if (!marked) return desc
    const [beforeMarked, afterMarked] = desc.split(marked)
    return [beforeMarked, <b key={marked}>{marked}</b>, afterMarked]
  }

  const titleStartWidth = currentLang === avaliableLangs.de ? 92 : 80

  return (
    <MainWrapper>
      <DoubleColumnsContainer>
        <PosterWrapper>
          <Poster imgSrc={posterSrc} />
        </PosterWrapper>

        <ContentWrapper titleStartWidth={titleStartWidth}>
          {coach && (
            <>
              <SectionTitleText
                text={coach.title}
                extraStyles={titleExtraStyles}
              />
              <PromotionTextWrapper>
                <DescTitleText>{coach.desc}</DescTitleText>
                <DescList>
                  {coach.list.map(item => {
                    return (
                      <DescListItem key={item.desc}>
                        -{'  '}
                        {renderListText(item)}
                      </DescListItem>
                    )
                  })}
                </DescList>
              </PromotionTextWrapper>
              {coach.coachLink && (
                <LinkWrapper href={coach.coachLink} target='_blank'>
                  <BuyButton
                    text={coach.moreInfo}
                    currentLang={currentLang}
                    isHeader
                  />
                </LinkWrapper>
              )}
            </>
          )}
        </ContentWrapper>
      </DoubleColumnsContainer>
    </MainWrapper>
  )
}

BibleCoachAd.propTypes = {
  coach: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  posterSrc: PropTypes.string,
  currentLang: PropTypes.string,
}

BibleCoachAd.defaultProps = {
  coach: null,
  posterSrc: '',
  currentLang: '',
}

export default BibleCoachAd
