/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SectionTitleText from '@common/SectionTitleText'

import Poster from '@common/Poster'
import DoubleColumnsContainer from '@containers/DoubleColumnsContainer'
import createSymbolBackgroundStyle from '@common/backgrounds/createSymbolBackgroundStyle'

const MainWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  margin-top: 140px;
  position: relative;
  &::before {
    ${createSymbolBackgroundStyle({
      width: '62%',
      height: '70%',
      top: '23%',
      right: '-5%',
    })}
  }
  ${props => props.theme.media.lessThan('990px')`
    &::before {
      width: 100vw;
      height: 60%;
    }
  `}
  ${props => props.theme.media.lessThan('mobile')`
    &::before {
      height: 54%;
    }
  `}
`
const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 70px 0 70px 30px;
  height: 100%;
  ${props => props.theme.flex.column}
  & h1 {
    width: 100%;
  }
  ${props => props.theme.media.lessThan('990px')`
    padding: 70px 0;
    justify-content: center;
    align-items: center;
  `}
`

const titleExtraStyles = `
  text-transform: uppercase;
  width: 80%;
  text-shadow: none;
  & > span {
   background: white; 
  }
  
`

const PromotionTextWrapper = styled.div`
  min-height: 236px;
  max-width: 650px;
  background: ${props => props.theme.promotionBGColor};
  color: ${props => props.theme.color.darkGrey};
  font-size: ${props => props.theme.fontSizes.size24};
  line-height: 28px;
  box-sizing: border-box;
  padding: 35px;
  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size16}
    min-height: 50px;
    max-width: 100%;
    line-height: 22px;
  `}
`

const PosterWrapper = styled.div`
  margin: 0 auto;
  height: 600px;
  width: 330px;
  ${props => props.theme.media.between('mobile', 'tablet')`
    height: 600px;
    width: 360px;
  `}
  ${props => props.theme.media.lessThan('mobile')`
      height: 330px;
      width: 200px;
  `}
`

const BiblePromotion = ({ promotion, posterSrc }) => {
  return (
    <MainWrapper>
      <DoubleColumnsContainer>
        <PosterWrapper>
          <Poster imgSrc={posterSrc} />
        </PosterWrapper>

        {promotion && (
          <ContentWrapper>
            <SectionTitleText
              text={promotion.title}
              extraStyles={titleExtraStyles}
            />
            <PromotionTextWrapper>{promotion.desc}</PromotionTextWrapper>
          </ContentWrapper>
        )}
      </DoubleColumnsContainer>
    </MainWrapper>
  )
}

BiblePromotion.propTypes = {
  promotion: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  posterSrc: PropTypes.string,
}

BiblePromotion.defaultProps = {
  promotion: '',
  posterSrc: '',
}

export default BiblePromotion
