import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DefaultButton from './DefaultButton'

const BuyButtonWrapper = styled(DefaultButton)`
  width: 290px;
  height: 50px;
  background-color: ${props => props.theme.buttonBGColor};
  border: none;
  font-size: 21px;
  font-weight: 900;
  letter-spacing: 0.24px;
  line-height: 24px;
  box-shadow: 5px 5px 0px 0px ${props => props.theme.color.aqua};
  box-sizing: border-box;
  color: ${props => props.theme.color.white};
  &:active {
    background-color: ${props => props.theme.buttonBGActiveColor};
    color: ${props => props.theme.color.white50};
    box-shadow: 5px 5px 0px 0px ${props => props.theme.color.aqua50};
  }

  ${props =>
    props.isHeader &&
    props.theme.media.lessThan('mobile')`
    width: 196px;
    height: 30px;
    font-size: ${props.currentLang === 'de' ? 15 : 17}px;
    letter-spacing: 0.3px;
  `}
`

const BuyButton = ({ text, onClick, isHeader, currentLang, isInactive }) => {
  return (
    text && (
      <BuyButtonWrapper
        onClick={onClick}
        isHeader={isHeader}
        currentLang={currentLang}
        disabled={isInactive} // prop is to just freeze the button and do nothing, if necessary
      >
        {text}
      </BuyButtonWrapper>
    )
  )
}

BuyButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isHeader: PropTypes.bool,
  currentLang: PropTypes.string,
  isInactive: PropTypes.bool,
}

BuyButton.defaultProps = {
  text: '',
  onClick: () => {},
  isHeader: false,
  currentLang: '',
  isInactive: false,
}

export default BuyButton
