import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ContainerWrapper = styled.div`
  ${props => props.extraStyles}
`

const DoubleColumnsContainer = ({ children, reverseOnMobile, extraStyles }) => {
  return (
    <ContainerWrapper extraStyles={extraStyles}>
      <Row type='flex'>
        <Col
          xs={{ span: 24, order: reverseOnMobile ? 2 : 1 }}
          sm={{ span: 24, order: reverseOnMobile ? 2 : 1 }}
          md={{ span: 24, order: reverseOnMobile ? 2 : 1 }}
          lg={{ span: 12, order: 1 }}
          xl={{ span: 12, order: 1 }}
        >
          {children[0] || <></>}
        </Col>
        <Col
          xs={{ span: 24, order: reverseOnMobile ? 1 : 2 }}
          sm={{ span: 24, order: reverseOnMobile ? 1 : 2 }}
          md={{ span: 24, order: reverseOnMobile ? 1 : 2 }}
          lg={{ span: 12, order: 2 }}
          xl={{ span: 12, order: 2 }}
        >
          {children[1] || <></>}
        </Col>
      </Row>
    </ContainerWrapper>
  )
}

DoubleColumnsContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  reverseOnMobile: PropTypes.bool,
  extraStyles: PropTypes.string,
}

DoubleColumnsContainer.defaultProps = {
  reverseOnMobile: false,
  extraStyles: '',
}

export default DoubleColumnsContainer
