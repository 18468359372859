import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import LangSwitcher from '@containers/LangSwitcher'
import Logo from '@common/Logo'
import WeCreateIcon from '@common/icons/WeCreateIcon'

const LogoImgWrapper = styled.div`
  width: 200px;
  height: 30px;
  padding-left: 5%;
  box-sizing: content-box;
  ${props => props.theme.media.lessThan('tablet')`
    // display: none;
    margin-right: 10px;
  `}
  ${props => props.theme.media.lessThan('590px')`
      width: 140px;
      height: 21px;
  `}
  ${props => props.theme.media.lessThan('400px')`
      width: 100px;
      height: 15px;
  `}
  ${props => props.theme.media.lessThan('350px')`
      width: 80px;
      height: 12px;
  `}
`

const HeaderWrapper = styled.div`
  ${props => props.theme.flex.display}
  margin-top: 10px;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${props => props.theme.media.lessThan('tablet')`
    padding-left: 5%;
  `}
`

const ButtonWrapper = styled.div`
  position: fixed;
  top: 22px;
  z-index: 10;
  ${props => props.theme.media.lessThan('mobile')`
    top: 30px;
  `}
`

const BuyButtonStickWrapper = styled.div`
  ${props => props.theme.flex.display}
  margin-right: 330px;
  ${props => props.theme.media.lessThan('mobile')`
    margin-right: 210px;
  `}
`

const LogoLangWrapper = styled.div`
  ${props => props.theme.flex.display}
  align-items: center;
  justify-content: space-between;
  width: 65%;
  ${props => props.theme.media.between('tablet', '1000px')`
    width: 55%;
  `}
`

const BiblePageHeader = ({ logoSrc, children }) => {
  return (
    <HeaderWrapper>
      <LogoLangWrapper>
        <WeCreateIcon />

        {/* PNG LOGO IS DISABLED, SVG USED INSTEAD */}
        {/* {logoSrc && (
          <LogoImgWrapper>
            <Logo imgSrc={logoSrc} />
          </LogoImgWrapper>
        )} */}

        {/* Temporary disabled */}
        {/* <LangSwitcher /> */}
      </LogoLangWrapper>

      <BuyButtonStickWrapper>
        <ButtonWrapper>{children}</ButtonWrapper>
      </BuyButtonStickWrapper>
    </HeaderWrapper>
  )
}

BiblePageHeader.propTypes = {
  logoSrc: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

BiblePageHeader.defaultProps = {
  logoSrc: '',
  children: <></>,
}

export default BiblePageHeader
