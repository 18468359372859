/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DoubleColumnsContainer from '@containers/DoubleColumnsContainer'

import Poster from '@common/Poster'

const MainWrapper = styled.div`
  margin: 90px auto;
  width: 95%;
  ${props => props.theme.media.lessThan('tablet')`
    width: 90%;
  `}
`

const HeaderWrapper = styled.div`
  color: ${props => props.theme.color.lightGrey};
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSizes.size38};
  font-weight: ${props => props.theme.fontWeight.w300};
  padding-left: 7.5%;
  width: fit-content;
  text-transform: uppercase;
  ${props => props.theme.media.lessThan('tablet')`
    padding-left: 5.5%;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size24};
    padding-left: 0;
  `}
`

const IconsGroupWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  border: 1px solid #979797;
  width: 600px;
  height: 186px;
  box-sizing: border-box;
  padding: 50px;
  margin: 40px 10px;
  position:relative;
  ${props => props.theme.media.lessThan('mobile')`
    padding: 10px;
    margin: 20px 0;
    height: 33vw;
  `}
  ${props => props.theme.media.between('mobile', 'tablet')`
    margin: 40px 5%;
  `}

  &::before {
    position:absolute;
    display: ${props => (props.oneBlockDisplay ? 'none' : 'block')};
    padding: 0 10px;
    color: #979797;
    font-family: ${props => props.theme.fontFamily.poppins};	
    font-size: 24px;	
    line-height: 28px;
    top: -14px;
    left: 65px;
    ${props => props.theme.media.lessThan('mobile')`
        left: 16px;
        font-size: 16px;
    `}
    background: ${props => props.theme.color.white};
    content: "${props => props.titleText}:";
    text-transform: uppercase;
    
  }
  & > img {
    height: unset;
  }
`
const IconsGroupInOneBlockWrapper = styled(IconsGroupWrapper)`
  border: none;
  margin: 0;
  padding: 50px 20px;
  // padding: 0;
  &::before {
    display: none;
  }
  ${props => props.theme.media.lessThan('tablet')`
    margin: 0;
  `}
`

const IconsGroupOuterWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
`

const extraDoubleContainerOneBlockStyles = `
    border: 1px solid #979797;
    margin-top: 30px;
`

const BiblePageWorkHistory = ({
  imgSrcs,
  title,
  oneBlockDisplay,
  imagesSizes,
}) => {
  const isRenderSrcs = Object.keys(imgSrcs).length
  return (
    <MainWrapper>
      <HeaderWrapper>{title}</HeaderWrapper>
      {isRenderSrcs && (
        <DoubleColumnsContainer
          extraStyles={
            oneBlockDisplay ? extraDoubleContainerOneBlockStyles : ''
          }
        >
          {Object.keys(imgSrcs).map(imgName => (
            <IconsGroupOuterWrapper key={imgName}>
              {oneBlockDisplay ? (
                <IconsGroupInOneBlockWrapper>
                  <Poster
                    imgSrc={imgSrcs[imgName]}
                    // height={`${imagesSizes.height}`}
                    // width={`${imagesSizes.width}`}
                  />
                </IconsGroupInOneBlockWrapper>
              ) : (
                <IconsGroupWrapper titleText={imgName}>
                  <Poster imgSrc={imgSrcs[imgName]} />
                </IconsGroupWrapper>
              )}
            </IconsGroupOuterWrapper>
          ))}
        </DoubleColumnsContainer>
      )}
    </MainWrapper>
  )
}

BiblePageWorkHistory.propTypes = {
  imgSrcs: PropTypes.object,
  title: PropTypes.string,
  oneBlockDisplay: PropTypes.bool,
  imagesSizes: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
}

BiblePageWorkHistory.defaultProps = {
  imgSrcs: {},
  title: '',
  oneBlockDisplay: false,
  imagesSizes: {
    height: 0,
    width: 0,
  },
}

export default BiblePageWorkHistory
