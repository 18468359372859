/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PaymentsIconsGroup from '@common/icons/PaymentsIconsGroup'

const PaymentWrapper = styled.div`
  width: 100%;
  max-width: 650px;
  ${props => props.theme.flex.display}
  justify-content: space-between;
  flex-wrap: wrap;
  ${props => props.theme.media.lessThan('640px')`
    justify-content: center;
    align-items: center;
  `}
`

const BibleReadySetGrowContent = ({ isLandingPage, children }) => {
  return (
    <PaymentWrapper>
      {children}
      {
        //<PaymentsIconsGroup isLandingPage={isLandingPage} />
      }
    </PaymentWrapper>
  )
}

BibleReadySetGrowContent.propTypes = {
  isLandingPage: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

BibleReadySetGrowContent.defaultProps = {
  isLandingPage: false,
  children: () => <></>,
}

export default BibleReadySetGrowContent
