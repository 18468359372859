import React from 'react'
import styled from 'styled-components'

const IconWrapper = styled.div`
  position: relative;
  height: 50px;
  width: 50px;
`

const SVG = styled.svg`
  height: 115px;
  width: 150px;
  position: absolute;
  top: -27px;
  left: 6px;
  ${props => props.theme.media.lessThan('tablet')`
    left: -32px;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    left: -32px;
    height: 92px;
    width: 120px;
    top: -20px;
  `}
`

const WeCreateIcon = () => {
  return (
    <IconWrapper>
      <SVG
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        viewBox='0 0 500 500'
        style={{ enableBackground: 'new 0 0 500 500' }}
      >
        <g>
          <path d='M127.35,353.51' />
          <g>
            <path
              d='M246.43,312.22h9.7l4.48,22.64l5.33-22.64h10.27l5.39,22.53l4.43-22.53h9.14l-8.57,31.67h-10.73l-4.99-20.54l-5.16,20.54
			h-10.67L246.43,312.22z'
            />
            <path
              d='M312.94,344.34c-9.31,0-16-6.24-16-16.29c0-10.04,6.58-16.29,16-16.29c9.25,0,15.78,6.13,15.78,15.78
			c0,0.91-0.06,1.87-0.17,2.84h-21.96c0.34,4.14,2.89,6.07,6.02,6.07c2.72,0,4.26-1.36,5.05-3.06h10.33
			C326.45,339.58,320.83,344.34,312.94,344.34z M306.64,324.99h12.14c0-3.46-2.72-5.45-5.96-5.45
			C309.65,319.54,307.21,321.47,306.64,324.99z'
            />
            <path
              d='M263.69,356.51c7.94,0,13.56,4.37,15.21,11.92h-10.33c-0.79-2.21-2.38-3.63-5.05-3.63c-3.46,0-5.85,2.72-5.85,8
			c0,5.28,2.38,8,5.85,8c2.67,0,4.2-1.3,5.05-3.63h10.33c-1.64,7.32-7.26,11.92-15.21,11.92c-9.31,0-15.89-6.24-15.89-16.29
			C247.79,362.81,254.38,356.51,263.69,356.51z'
            />
            <path d='M293.76,388.63h-9.7v-31.67h9.7v5.28c2.21-3.35,5.68-5.62,9.93-5.62v10.27h-2.67c-4.6,0-7.26,1.59-7.26,7.04V388.63z' />
            <path
              d='M322.42,389.08c-9.31,0-16-6.24-16-16.29c0-10.04,6.58-16.29,16-16.29c9.25,0,15.78,6.13,15.78,15.78
			c0,0.91-0.06,1.87-0.17,2.84h-21.96c0.34,4.14,2.89,6.07,6.02,6.07c2.72,0,4.26-1.36,5.05-3.06h10.33
			C335.93,384.32,330.31,389.08,322.42,389.08z M316.12,369.73h12.14c0-3.46-2.72-5.45-5.96-5.45
			C319.13,364.28,316.69,366.21,316.12,369.73z'
            />
            <path
              d='M355.33,356.51c4.71,0,8.06,2.16,9.76,4.94v-4.48h9.7v31.67h-9.7v-4.48c-1.76,2.78-5.11,4.94-9.82,4.94
			c-7.72,0-13.9-6.36-13.9-16.34C341.37,362.75,347.56,356.51,355.33,356.51z M358.17,364.96c-3.63,0-6.92,2.72-6.92,7.77
			c0,5.05,3.29,7.89,6.92,7.89c3.69,0,6.92-2.78,6.92-7.83C365.1,367.75,361.86,364.96,358.17,364.96z'
            />
            <path
              d='M383.37,365.02h-3.86v-8.06h3.86v-7.72h9.71v7.72h6.36v8.06h-6.36v12.71c0,1.87,0.79,2.67,2.95,2.67h3.46v8.23h-4.94
			c-6.58,0-11.18-2.78-11.18-11.01V365.02z'
            />
            <path
              d='M418.95,389.08c-9.31,0-16-6.24-16-16.29c0-10.04,6.58-16.29,16-16.29c9.25,0,15.78,6.13,15.78,15.78
			c0,0.91-0.06,1.87-0.17,2.84h-21.96c0.34,4.14,2.89,6.07,6.02,6.07c2.72,0,4.26-1.36,5.05-3.06h10.33
			C432.46,384.32,426.84,389.08,418.95,389.08z M412.65,369.73h12.14c0-3.46-2.72-5.45-5.96-5.45
			C415.66,364.28,413.22,366.21,412.65,369.73z'
            />
          </g>
          <g>
            <path
              d='M269.25,163.79l-19.12-11.11c-5.23-3.04-5.22-10.6,0.02-13.63l46.9-27.08c2.44-1.41,5.45-1.41,7.89,0.01l19.12,11.11
			c5.23,3.04,5.22,10.6-0.02,13.63l-46.9,27.08C274.7,165.21,271.69,165.21,269.25,163.79z'
            />
          </g>
          <g>
            <path
              d='M269.25,221.02l-19.12-11.11c-5.23-3.04-5.22-10.6,0.02-13.63l46.9-27.08c2.44-1.41,5.45-1.41,7.89,0.01l19.12,11.11
			c5.23,3.04,5.22,10.6-0.02,13.63l-46.9,27.08C274.7,222.44,271.69,222.44,269.25,221.02z'
            />
          </g>
          <g>
            <path
              d='M269.25,278.25l-19.12-11.11c-5.23-3.04-5.22-10.6,0.02-13.63l46.9-27.08c2.44-1.41,5.45-1.41,7.89,0.01l19.12,11.11
			c5.23,3.04,5.22,10.6-0.02,13.63l-46.9,27.08C274.7,279.67,271.69,279.67,269.25,278.25z'
            />
          </g>
          <g>
            <path
              d='M227.14,175.8l-26.88-15.62c-3-1.74-6.84-0.12-7.67,3.25l-20.72,83.96l21.26,57.45c0.47,1.19,1.45,3.37,2.58,3.3
			c0.81-0.05,1.3-1.39,1.63-2.57l31.2-126.47C228.86,177.81,228.29,176.47,227.14,175.8z'
            />
            <path
              fill='#414042'
              d='M160.69,219.09c-0.23-0.2-0.47-0.37-0.74-0.53l-1.9-1.03l2.37,1.38C160.52,218.96,160.6,219.03,160.69,219.09
			z'
            />
            <path
              fill='#414042'
              d='M197.34,305.58c-0.32,1.18-0.82,2.52-1.63,2.57c-1.13,0.07-2.11-2.11-2.58-3.3l-21.26-57.45l-9.66-26.11
			c-0.17-0.47-0.42-0.9-0.72-1.29c0.38,0.66,0.51,1.45,0.32,2.22l-10.57,42.84l10.93,30.01c0.43,1.17,1.25,2.16,2.33,2.78
			l24.32,14.14c1.39,0.81,3.09,1,4.29,0.46c0,0,0,0,0,0c0.07-0.02,0.12-0.06,0.19-0.09c0.02-0.01,0.04-0.02,0.07-0.03
			c1.59-0.61,2.74-1.82,3.15-3.48l0.87-3.52C197.38,305.41,197.36,305.49,197.34,305.58z'
            />
            <path
              fill='#414042'
              d='M197.4,305.33c0.12-0.45,0.21-0.87,0.28-1.15L197.4,305.33z'
            />
            <path d='M160.69,219.09c0.31,0.26,0.57,0.57,0.81,0.9C161.3,219.65,161.03,219.34,160.69,219.09z' />
            <path
              d='M161.5,220c-0.24-0.33-0.5-0.64-0.81-0.9c-0.09-0.06-0.17-0.13-0.27-0.19l-2.37-1.38l-24.51-14.24
			c-3-1.74-6.84-0.12-7.67,3.25l-20.72,83.96l21.26,57.45c0.47,1.19,1.45,3.37,2.58,3.3c0.81-0.05,1.3-1.39,1.63-2.57l20.63-83.63
			l10.57-42.84C162.02,221.44,161.88,220.65,161.5,220z'
            />
            <path
              fill='#414042'
              d='M130.62,348.69c-0.32,1.18-0.82,2.52-1.63,2.57c-1.13,0.07-2.11-2.11-2.58-3.3l-21.26-57.45L95.5,264.4
			c-0.42-1.14-1.22-2.1-2.26-2.73l-19.88-11.89c-4.31-2.57-9.46,1.69-7.74,6.4l29.86,81.98c0.43,1.17,1.25,2.16,2.33,2.78
			l24.32,14.14c1.39,0.81,3.09,1,4.29,0.46c0,0,0,0,0,0c0.07-0.02,0.12-0.06,0.19-0.09c0.02-0.01,0.04-0.02,0.07-0.03
			c1.59-0.61,2.74-1.82,3.15-3.48l0.87-3.52C130.67,348.52,130.65,348.6,130.62,348.69z'
            />
            <path
              fill='#414042'
              d='M130.69,348.44c0.12-0.45,0.21-0.87,0.28-1.15L130.69,348.44z'
            />
          </g>
        </g>
      </SVG>
    </IconWrapper>
  )
}

export default WeCreateIcon
