import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const LogoImg = styled.img`
  width: ${props => (props.width ? `${props.width}px` : 'inherit')};
  height: ${props => (props.height ? `${props.height}px` : 'inherit')};
`

const Logo = ({ imgSrc, width, height }) => {
  return <LogoImg src={imgSrc} alt='logo' width={width} height={height} />
}

Logo.propTypes = {
  imgSrc: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

Logo.defaultProps = {
  imgSrc: '',
  width: 0,
  height: 0,
}

export default Logo
