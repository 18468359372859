/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import BibleCustomSlider from '@common/sliders/BibleCustomSlider'

const BibleInsideReport = ({ insideReport, slides }) => {
  // slides scrs are mapped with corresponding texts
  const slidesWithSrc = slides.map((slide, idx) => ({
    src: slide,
    slide: insideReport.slides[idx],
  }))

  return (
    <BibleCustomSlider
      slidesWithSrc={slidesWithSrc}
      title={insideReport.title}
    />
  )
}

BibleInsideReport.propTypes = {
  insideReport: PropTypes.object.isRequired,
  slides: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default BibleInsideReport
