import { useContext } from 'react'
import { LocalizationContext } from '@providers/LocalizationProvider'

const useLocalizedStrings = () => {
  const { labelStrings, changeCurrentLang, currentLang } = useContext(
    LocalizationContext,
  )
  return { labelStrings, changeCurrentLang, currentLang }
}

export default useLocalizedStrings
