/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MarkedRecipesHeader } from '@common/MarkedHeader'
import createSymbolBackgroundStyle from '@common/backgrounds/createSymbolBackgroundStyle'
import { createStrWithMarkedPart, detectBrowser } from '../../../utils/jsUtils'

const MainWrapper = styled.div`
  width: 94%;
  margin: 0 auto;
  margin-top: 140px;
  position: relative;
  &::before {
    ${createSymbolBackgroundStyle({
      width: '40%',
      height: '70%',
      left: '-3%',
      top: '-9%',
    })}
  }
  &::after {
    ${createSymbolBackgroundStyle({
      width: '40%',
      height: '20%',
      right: '-3%',
      bottom: '-20%',
    })}
  }
  ${props => props.theme.media.between('1100px', '1140px')`
    &::after {
      bottom: 0;
    }
  `}
  ${props => props.theme.media.lessThan('1100px')`
    &::before {
      width: 60%;
      height: 54%;
    }
    &::after {
      bottom: 0;
      width: 50%;
    }
  `}
  ${props => props.theme.media.lessThan('tablet')`
    &::before {
      top: -5%;
      left: -5%;
      width: 100vw;
      height: 47%;
    }
  `}
  ${props => props.theme.media.lessThan('680px')`
    &::before {
      height: 35%;
    }
    &::after {
      height: 68%;
      width: 100vw;
      bottom: -15%;
    }
  `}
  ${props => props.theme.media.lessThan('mobile')`
    margin-top: 30px;
    &::before {
      top: -2%;
      height: 32%;
    }
  `}
`
const MarkedTextWrapper = styled.div`
max-width: 80%;
margin-left: 23px;
position: relative;
${props =>
  props.isFirefox &&
  `
    &::before {
    position: absolute;
    content: '';
    bottom: -5.5px;
    left: -23px;
    background: ${props.theme.recipesMarkedHeaderBGColor};
    height: 100%;
    width: 23px;
    }
  `}
  ${props => props.theme.media.lessThan('800px')`
    padding-left: 10px;
    max-width: 100%;
    margin-left: 10px;
    ${props.isFirefox &&
      `
    &::before {
        left: -13px;
    }
    `}
  `}
  ${props => props.theme.media.lessThan('mobile')`
      ${props.isFirefox &&
        `
      &::before {
          bottom: -0.5%;
          width: 7px;
          left: 3px;
      }
      `}
  `}
`
const RecipesWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  ${props => props.theme.flex.centerAll}
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1000px) {
    justify-content: space-around;
  }
`
const RecipWrapper = styled.div`
  height: 250px;
  width: 250px;
  border: 7px solid ${props => props.theme.punksBorderColor};
  margin: 20px 0;
  ${props => props.theme.media.between('mobile', '1120px')`
    margin-right: 50px;
  `}
`
const RecipNumberWrapper = styled.div`
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSizes.size80};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 58px;
  text-shadow: 1px 1px ${props => props.theme.color.black};
  padding-left: 15px;
  margin-top: 30px;
`
const RecipTextWrapper = styled.div`
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSizes.size20};
  line-height: 28px;
  padding: 0 10px 0 15px;
  margin-top: 20px;
  & > span {
    background: ${props => props.theme.color.white};
  }
`

const BoldTextWrapper = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
`

const BibleRecipes = ({ recipes }) => {
  const [isFirefox, detectFirefox] = useState(false)
  useEffect(() => {
    detectFirefox(detectBrowser('firefox'))
  }, [])
  return (
    <MainWrapper>
      {recipes && (
        <>
          <MarkedTextWrapper isFirefox={isFirefox}>
            <MarkedRecipesHeader>{recipes.title}</MarkedRecipesHeader>
          </MarkedTextWrapper>
          <RecipesWrapper>
            {recipes.steps.map(step => (
              <RecipWrapper key={step.text}>
                <RecipNumberWrapper>{step.number}</RecipNumberWrapper>
                <RecipTextWrapper>
                  {step.marked
                    ? createStrWithMarkedPart(
                        step.text,
                        step.marked,
                        BoldTextWrapper,
                      )
                    : step.text}
                </RecipTextWrapper>
              </RecipWrapper>
            ))}
          </RecipesWrapper>
        </>
      )}
    </MainWrapper>
  )
}

BibleRecipes.propTypes = {
  recipes: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

BibleRecipes.defaultProps = {
  recipes: '',
}

export default BibleRecipes
