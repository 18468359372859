import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PosterWrapper = styled.img`
  height: ${props => (props.height ? `${props.height}px` : 'inherit')};
  width: ${props => (props.width ? `${props.width}px` : 'inherit')};
  ${props => props.extraStyles}
`

const Poster = ({ imgSrc, height, width, extraStyles }) => {
  return (
    <PosterWrapper
      src={imgSrc}
      alt='poster'
      height={height}
      width={width}
      extraStyles={extraStyles}
    />
  )
}

Poster.propTypes = {
  imgSrc: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  extraStyles: PropTypes.string,
}

Poster.defaultProps = {
  imgSrc: '',
  height: 0,
  width: 0,
  extraStyles: '',
}

export default Poster
