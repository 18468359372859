/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import FooterContainer from '@containers/FooterContainer'
import CookiesConsent from '@common/CookiesConsent'
import PunksThemeProvider from '@providers/PunksThemeProvider'
import { bibleTheme } from '@styles/styledThemes'
import LocalizationProvider from '@providers/LocalizationProvider'
import fbBibleStrings from '@localization/fbBiblePages'
import avaliableLangs from '@localization/avaliableLangs'
import {
  LayoutWrapper,
  LayoutChildrenWrapper,
} from '@common/CommonLayoutsStyles'

const BibleLayout = ({ children, showTaxesNote, location, pageLanguage }) => {
  return (
    <PunksThemeProvider theme={bibleTheme}>
      <LocalizationProvider
        labelStrings={fbBibleStrings}
        pageLanguage={pageLanguage}
      >
        <LayoutWrapper>
          <LayoutChildrenWrapper>{children}</LayoutChildrenWrapper>
          <FooterContainer showTaxesNote={showTaxesNote} location={location} />
        </LayoutWrapper>

        <CookiesConsent />
      </LocalizationProvider>
    </PunksThemeProvider>
  )
}

BibleLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showTaxesNote: PropTypes.bool,
  location: PropTypes.object.isRequired,
  pageLanguage: PropTypes.string,
}

BibleLayout.defaultProps = {
  showTaxesNote: false,
  pageLanguage: avaliableLangs.en,
}

export default BibleLayout
