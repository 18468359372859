/* eslint-disable */
import React, { useState, useLayoutEffect } from 'react'
import { use } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from '@common/Logo'

const GroupWrapper = styled.div`
  display: flex;
  width: 295px;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
`

const PaymentsIconsGroup = ({ isLandingPage, location }) => {
  const [pathPrefix, changePathPrefix] = useState(isLandingPage ? '' : '../../')
  useLayoutEffect(() => {
    if (window && window.location && !isLandingPage) {
      const pathnameArr = window.location.pathname
        .split('/')
        .filter(str => !!str)
      const path =
        pathnameArr.length === 0 ? '' : '../'.repeat(pathnameArr.length)
      changePathPrefix(path)
    }
  }, [])
  const createImgPath = path => `${pathPrefix}payments/${path}`

  return (
    <GroupWrapper>
      <Logo imgSrc={createImgPath('Visa.png')} width={64} height={21} />
      <Logo
        imgSrc={createImgPath('mastercard-logo.png')}
        width={37}
        height={30}
      />
      <Logo imgSrc={createImgPath('Maestro.png')} width={36} height={29} />
      <Logo
        imgSrc={createImgPath('American_Express.png')}
        width={28}
        height={28}
      />
      <Logo imgSrc={createImgPath('PayPallogo.png')} width={90} height={22} />
    </GroupWrapper>
  )
}

PaymentsIconsGroup.propTypes = {
  isLandingPage: PropTypes.bool,
}

PaymentsIconsGroup.defaultProps = {
  isLandingPage: false,
}

export default PaymentsIconsGroup
