import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const CustomizableSlider = ({
  slidesToRender,
  toRightArrow,
  toLeftArrow,
  infinite,
}) => {
  return (
    <Carousel
      //   partialVisible
      //   arrows
      infinite={infinite}
      responsive={responsive}
      swipeable
      {...(toRightArrow && { customRightArrow: toRightArrow })}
      {...(toLeftArrow && { customLeftArrow: toLeftArrow })}
    >
      {slidesToRender}
    </Carousel>
  )
}

CustomizableSlider.propTypes = {
  slidesToRender: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node, PropTypes.element),
  ]).isRequired,
  toRightArrow: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  toLeftArrow: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  infinite: PropTypes.bool,
}

CustomizableSlider.defaultProps = {
  toLeftArrow: false,
  toRightArrow: false,
  infinite: true,
}

export default CustomizableSlider
