import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Title = styled.h1`
  width: 450px;
  color: ${props => props.theme.color.black};
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSizes.size46};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: 58px;
  text-shadow: 1px 1px #000000;
  ${props => props.extraStyles}

  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size28};
    line-height: 34px;
    width: fit-content;
  `}
`

const SectionTitleText = ({ text, extraStyles, currentLang }) => {
  const isTextArray = Array.isArray(text)
  return (
    <Title extraStyles={extraStyles}>
      {isTextArray ? (
        <span>
          <span>
            {currentLang === 'de' ? `${text[0]} ${text[1]}` : text[0]}
          </span>
          <br />
          <span>{text.slice(currentLang === 'de' ? 2 : 1).join(' ')}</span>
        </span>
      ) : (
        <span>{text}</span>
      )}
    </Title>
  )
}

SectionTitleText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extraStyles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  currentLang: PropTypes.string,
}

SectionTitleText.defaultProps = {
  text: '',
  extraStyles: '',
  currentLang: 'en',
}

export default SectionTitleText
