/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import YouTube from 'react-youtube'

const YouTubeWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  justify-content: start;
  width: 100%;
  ${props => props.theme.media.lessThan('990px')`
    justify-content: center;
  `}
  & iframe {
    width: 500px;
    height: 300px;
    ${props => props.theme.media.between('990px', '1050px')`
      width: 455px;
    `}
    ${props => props.theme.media.between('tablet', '990px')`
      width: 550px;
      height: 330px;
    `}
    ${props => props.theme.media.between('mobile', 'tablet')`
      width: 480px;
      height: 290px;
    `}
    ${props => props.theme.media.lessThan('mobile')`
      width: 380px;
      height: 220px;
    `}
    ${props => props.theme.media.lessThan('350px')`
      width: 300px;
    `}
  }
`

const YoutubeVideo = ({ videoId }) => {
  return (
    <YouTubeWrapper>
      <YouTube videoId={videoId} opts={{ playerVars: { rel: 0 } }} />
    </YouTubeWrapper>
  )
}

YoutubeVideo.propTypes = {
  videoId: PropTypes.string,
}

YoutubeVideo.defaultProps = {
  videoId: '',
}

export default YoutubeVideo
