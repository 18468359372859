/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { GlobalPunksStyles } from '@styles/styledThemes'

/**
 * NOTE: As we can have few themes for different pages, PunksThemeProvider wrapps the particulat layout to provide
 * diff styles for diff pages and theme to apply is passed into provider
 *  @param theme: current theme object from styledThemes.js is passed from particular layout
 */

const PunksThemeProvider = ({ children, theme }) => (
  <ThemeProvider theme={theme}>
    {children}
    <GlobalPunksStyles />
  </ThemeProvider>
)

PunksThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,

  theme: PropTypes.object.isRequired,
}

export default PunksThemeProvider
