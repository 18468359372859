import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SectionTitleText from '@common/SectionTitleText'

import Poster from '@common/Poster'
import DoubleColumnsContainer from '@containers/DoubleColumnsContainer'
import createSymbolBackgroundStyle from '@common/backgrounds/createSymbolBackgroundStyle'
import BuyButton from '@common/buttons/BuyButton'
import avaliableLangs from '@localization/avaliableLangs'

const MainWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  margin-top: 150px;
  margin-bottom: 150px;
  position: relative;
  &::before {
    ${createSymbolBackgroundStyle({
      width: '62%',
      height: '75%',
      top: '7%',
      right: '-5%',
    })}
  }
  ${props => props.theme.media.lessThan('1100px')`
    margin-top: 105px;
  `}

  ${props => props.theme.media.lessThan('990px')`
    &::before {
      width: 100vw;
      height: 60%;
    }
  `}
  ${props => props.theme.media.lessThan('mobile')`
    margin-bottom: 60px;
    &::before {
      height: 80%;
      right: -14%;
    }
  `}
`
const ContentWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  left: 55px;
  top: -25px;
  height: 100%;
  ${props => props.theme.flex.column}
  align-items: center;
  justify-content: space-between;
  & h1 {
    width: ${props => props.titleStartWidth || 100}%;
    margin: 0 auto;
  }
  ${props => props.theme.media.lessThan('desktop')`
    left: 35px;
    & h1 {
      width: 80%;
      font-size: 34px;
      line-height: 44px;
    }
  `}
  ${props => props.theme.media.lessThan('1100px')`
    left: 40px;
    top: -30px;
    
  `}
  ${props => props.theme.media.lessThan('990px')`
    left: unset;
    top: unset;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
    & h1 {
      width: 90%;
      font-size: 35px;
    }
  `}
  ${props => props.theme.media.lessThan('mobile')`
    & h1 {
      width: 100%;
      font-size: 28px;
      line-height: 35px;
    }
  `}
`

const titleExtraStyles = `
  text-transform: uppercase;
  width: 80%;
  text-shadow: none;
  & > span {
   background: white; 
  }
  
`
const LInkBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  margin: 0 auto;
  ${props => props.theme.media.lessThan('990px')`
    padding-left: 0;
    width: 75%;
  `}
  ${props => props.theme.media.lessThan('tablet')`
    width: 84%;
  `}

  ${props => props.theme.media.lessThan('mobile')`
    width: 98%;
  `}
`

const PromotionTextWrapper = styled.div`
  min-height: 236px;
  max-width: 650px;
  background: ${props => props.theme.color.yellow};
  color: ${props => props.theme.color.darkGrey};
  font-size: ${props => props.theme.fontSizes.size22};
  line-height: 28px;
  box-sizing: border-box;
  padding: 25px 45px;
  margin: 20px 0 10px 0;
  ${props => props.theme.media.lessThan('desktop')`
    font-size: ${props.theme.fontSizes.size20};
  `}
  ${props => props.theme.media.lessThan('550px')`
    padding: 25px;
  `}

  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size16}
    min-height: 50px;
    max-width: 100%;
    line-height: 22px;
  `}
`

const PosterWrapper = styled.div`
  margin: 0 auto;
  height: 464px;
  width: 650px;
  position: relative;
  ${props => props.theme.media.lessThan('desktop')`
      height: 421px;
      width: 590px;
  `}
  ${props => props.theme.media.between('tablet', 'desktop')`
      height: 382px;
      width: 535px;
  `}
  ${props => props.theme.media.between('mobile', 'tablet')`
    height: 364px;
    width: 510px;
  `}
  ${props => props.theme.media.lessThan('550px')`
    height: 321px;
    width: 450px;
  `}
  ${props => props.theme.media.lessThan('mobile')`
      height: 242px;
      width: 340px;
  `}
  ${props => props.theme.media.lessThan('400px')`
      height: 185px;
      width: 260px;
  `}
`

const PosterExtraImgWrapper = styled.div`
  position: absolute;
  right: 9%;
  top: -18%;
  height: 200px;
  width: 200px;
  ${props => props.theme.media.lessThan('desktop')`
    height: 181px;
    width: 181px;
  `}
  ${props => props.theme.media.between('tablet', 'desktop')`
    height: 164px;
    width: 164px;
  `}
  ${props => props.theme.media.between('mobile', 'tablet')`
  height: 156px;
  width: 156px;
  `}
  ${props => props.theme.media.lessThan('550px')`
  height: 138px;
  width: 138px;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    height: 104px;
    width: 104px;
  `}
  ${props => props.theme.media.lessThan('400px')`
    height: 80px;
    width: 80px;
  `}
`

const LinkWrapper = styled.a`
  display: block;
`

const SaveMoneyTextWrapper = styled.div`
  font-size: ${props => props.theme.fontSizes.size25};
  font-weight: ${props => props.theme.fontWeight.bold};
  font-family: ${props => props.theme.fontFamily.poppins};
  text-transform: uppercase;
  line-height: 58px;
  background: ${props => props.theme.color.white};
  ${props => props.theme.media.lessThan('desktop')`
    font-size: ${props.theme.fontSizes.size22};
    line-height: 50px;
  `}
  ${props => props.theme.media.lessThan('1100px')`
    font-size: ${props.theme.fontSizes.size16};
    line-height: 34px;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size14};
  `}
`

const BibleCourseAd = ({ course, posterSrc, guaranteeSrc, currentLang }) => {
  const renderListText = ({ text, marked }) => {
    if (!marked) return text
    const [beforeMarked, afterMarked] = text.split(marked)
    return [beforeMarked, <b key={marked}>{marked}</b>, afterMarked]
  }

  const titleStartWidth = currentLang === avaliableLangs.de ? 87 : 85

  return (
    <MainWrapper>
      <DoubleColumnsContainer>
        <PosterWrapper>
          <Poster imgSrc={posterSrc} />
          {guaranteeSrc && (
            <PosterExtraImgWrapper>
              <Poster imgSrc={guaranteeSrc} />
            </PosterExtraImgWrapper>
          )}
        </PosterWrapper>

        <ContentWrapper titleStartWidth={titleStartWidth}>
          {course && (
            <>
              <SectionTitleText
                text={course.title}
                extraStyles={titleExtraStyles}
              />
              <PromotionTextWrapper>
                {renderListText(course.desc)}
              </PromotionTextWrapper>

              <LInkBlockWrapper>
                <SaveMoneyTextWrapper>{course.saveMoney}</SaveMoneyTextWrapper>

                {course.courseLink && (
                  <LinkWrapper href={course.courseLink} target='_blank'>
                    <BuyButton
                      text={course.moreInfo}
                      currentLang={currentLang}
                      isHeader
                    />
                  </LinkWrapper>
                )}
              </LInkBlockWrapper>
            </>
          )}
        </ContentWrapper>
      </DoubleColumnsContainer>
    </MainWrapper>
  )
}

BibleCourseAd.propTypes = {
  course: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  posterSrc: PropTypes.string,
  currentLang: PropTypes.string,
  guaranteeSrc: PropTypes.string,
}

BibleCourseAd.defaultProps = {
  course: null,
  posterSrc: '',
  currentLang: '',
  guaranteeSrc: '',
}

export default BibleCourseAd
