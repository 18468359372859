/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import avaliableLangs from '@localization/avaliableLangs'

export const LocalizationContext = React.createContext({
  currentLang: '',
  labelStrings: {},
  changeCurrentLang: () => {},
})

/**
 * NOTE:
 * LocalizationProvider is a reused provider which works with localized strings, depending on page.
 * That is why it is not applied in MainProvider, but should wrap the particular page and localized strings
 * should be passed as prop labelStrings. It is preferable to wrap page layout with LocalizationProvider
 *
 * @param labelStrings: object with localized strings for particular page.Used for changing lang and access to texts
 *
 * Localized strings, current language and method to change lang
 * are avaliable in useLocalizedStrings hook in @hooks/useLocalizedStrings.js
 */

const LocalizationProvider = ({ children, labelStrings, pageLanguage }) => {
  // currently force to english in any browser, not looking on browser language
  // to take browser lang use labelStrings.getlanguage()
  labelStrings.setLanguage(pageLanguage)
  const [currentLang, setCurrentLang] = useState(pageLanguage)

  const changeCurrentLang = lang => {
    labelStrings.setLanguage(lang)
    setCurrentLang(labelStrings.getLanguage())
  }

  const providerValue = {
    currentLang,
    labelStrings,
    changeCurrentLang,
  }
  return (
    <LocalizationContext.Provider value={providerValue}>
      {children}
    </LocalizationContext.Provider>
  )
}

LocalizationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  labelStrings: PropTypes.object.isRequired,
  pageLanguage: PropTypes.string,
}

LocalizationProvider.defaultProps = {
  pageLanguage: avaliableLangs.en,
}

export default LocalizationProvider
