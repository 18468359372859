import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Poster from '@common/Poster'
import YoutubeVideo from '@common/videoPlayers/YoutubeVideo'

const CapricornWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  justify-content: flex-end;
  ${props => props.theme.media.lessThan('1000px')`
    justify-content: center;
  `}
  & img {
    height: 530px;
    width: 460px;
  }
  ${props => props.theme.media.between('mobile', '570px')`
    & img {
      height: 430px;
      width: 390px;
    }
  `}
  ${props => props.theme.media.lessThan('mobile')`
    & img {
      height: 340px;
      width: 326px;
    }
  `}
`

const PlayerWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  height: 100%;
  width: 100%;
  align-items: self-start;
  margin-top: 45px;
  & > div {
    ${props => props.theme.flex.centerAll}
  }
  ${props => props.theme.media.greaterThan('desktop')`
    & > div iframe {
      height: 430px;
      width: 535px;
    }
  `}
  ${props => props.theme.media.between('1100px', 'desktop')`
    & > div iframe {
      height: 380px;
      width: 480px;
    }
  `}
  ${props => props.theme.media.between('1000px', '1100px')`
    & > div iframe {
      height: 380px;
      width: 450px;
    }
  `}
  ${props => props.theme.media.lessThan('990px')`
    margin-top: 0;
  `}
`

const BibleMainInfoRightSide = ({ posterImgSrc, videoId, showVideo }) => {
  return (
    <>
      {posterImgSrc && (
        <CapricornWrapper>
          <Poster imgSrc={posterImgSrc} />
        </CapricornWrapper>
      )}

      {showVideo && (
        <PlayerWrapper>
          <YoutubeVideo videoId={videoId} />
        </PlayerWrapper>
      )}
    </>
  )
}

BibleMainInfoRightSide.propTypes = {
  posterImgSrc: PropTypes.string,
  videoId: PropTypes.string,
  showVideo: PropTypes.bool,
}

BibleMainInfoRightSide.defaultProps = {
  posterImgSrc: '',
  videoId: '',
  showVideo: false,
}

export default BibleMainInfoRightSide
