/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import reactStringReplace from 'react-string-replace'
import SectionTitleText from '@common/SectionTitleText'
import Logo from '@common/Logo'

import Poster from '@common/Poster'
import DoubleColumnsContainer from '@containers/DoubleColumnsContainer'
import createSymbolBackgroundStyle from '@common/backgrounds/createSymbolBackgroundStyle'
import { createStrWithMarkedUpperCasedPart } from '../../../utils/jsUtils'

const MainWrapper = styled.div`
  position: relative;
  width: 94%;
  margin: 0 auto;
  margin-top: 130px;
  min-height: 580px;
  background: ${props => props.theme.authorBGColor};
  box-shadow: 7px 7px #000000;
  &::before {
    ${createSymbolBackgroundStyle({
      width: '76%',
      height: '118%',
      top: '-9%',
      left: '-3%',
      zIndex: 0,
    })}
  }

  ${props => props.theme.media.lessThan('990px')`
    height: fit-content;
    margin-top: 50px;
    &::before {
      top: 3%;
      left: -5%;
      height: 73%;
      width: 100vw;
    }
  `}
`
const ContentWrapper = styled.div`
  box-sizing: border-box;
  padding: 44px;
  height: 100%;
  min-height: 580px;
  ${props => props.theme.flex.column}
  ${props => props.theme.media.between('990px', '1150px')`
    padding-right: 90px;
  `}
  ${props => props.theme.media.lessThan('990px')`
    padding-bottom: 0;
    min-height: unset;
  `}
  ${props => props.theme.media.lessThan('640px')`
    padding: 40px;
  `}
`

const PosterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: -30px;
  height: ${props => (props.isSmaller ? 680 : 800)}px;
  width: ${props => (props.isSmaller ? 540 : 620)}px;

  ${props => props.theme.media.lessThan('990px')`
    position: relative;
    bottom: unset;
    right: unset;
    height: fit-content;
    margin: 0 auto;
  `}

  ${props => props.theme.media.between('tablet', '1100px')`
    height: 740px;
    width: 590px;
  `}

  ${props => props.theme.media.lessThan('tablet')`
    height: 610px;
    width: 460px;
  `}

  ${props => props.theme.media.lessThan('550px')`
    height: 435px;
    width: 350px;
  `}

  ${props => props.theme.media.lessThan('mobile')`
    height: 420px;
    width: 335px;
  `}
  ${props => props.theme.media.lessThan('350px')`
    height: 380px;
    width: 100%;
  `}
`

const AuthorTextCommonWrapper = styled.div`
  font-size: ${props => props.theme.fontSizes.size18};
  line-height: 1.3;
  padding-right: 15px;
  background: ${props => props.theme.authorBGColor};
  ${props => props.theme.media.lessThan('mobile')`
    font-size: ${props.theme.fontSizes.size16}
  `}
`

const AuthorNameWrapper = styled(AuthorTextCommonWrapper)`
  color: ${props => props.theme.color.white};
  background: ${props => props.theme.color.black};
  font-family: ${props => props.theme.fontFamily.poppins};
  font-weight: ${props => props.theme.fontWeight.w500};
  line-height: 24px;
  height: 45px;
  width: 216px;
  ${props => props.theme.flex.centerAll}
  margin: 0 auto 0 0;
  &:hover {
    ${props =>
      props.withSocialLink &&
      `color: ${props.theme.color.white90};
    `}

  ${props => props.theme.media.lessThan('mobile')`
    height: 40px;
    width: 131px;
  `}
`

const AuthorDescWrapper = styled(AuthorTextCommonWrapper)`
  color: ${props => props.theme.color.white};
  line-height: 28px;
  margin-top: 30px;
  font-weight: 300;
  b {
    font-weight: 700;
  }
  ${props => props.theme.media.lessThan('mobile')`
    line-height: 22px;
  `}
`
const CertifWrapper = styled.div`
  display: flex;
`

const CertLogoWrapper = styled.div`
  height: 150px;
  width: 150px;
  ${props => props.theme.media.lessThan('mobile')`
    height: 88px;
    width: 88px;
  `}
`
const TitleWrapper = styled.div`
  & > h1 {
    text-transform: uppercase;
    width: 290px;
    color: #ffffff;
    text-shadow: none;
    background: ${props => props.theme.authorBGColor};
  }
  ${props => props.theme.media.lessThan('mobile')`
    & h1 {
      width: 200px;
    }
  `}
`

const TextLinkWrapper = styled.a`
  color: ${props => props.theme.color.white};
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.color.white};
  }
`

const WithSocialLinkWrapper = styled.a`
  display: block;
  text-decoration: none;
`

const BibleAuthor = ({
  author,
  posterSrc,
  certLogos,
  withCerts,
  currentLang,
  withSocialLink,
  socialLink,
}) => {
  const preparedTitleText = author.title.split(' ')
  const createAuthorText = element => {
    const returnText = element.marked
      ? createStrWithMarkedUpperCasedPart(element.text, element.marked)
      : element.text

    if (element.links && Object.keys(element.links).length) {
      const splittedText = Array.isArray(returnText)
        ? returnText
        : returnText.split(' ')
      const keys = Object.keys(element.links)
      return splittedText.map((el, i) => {
        if (typeof el !== 'string') return el
        if (keys.includes(el)) {
          return (
            <TextLinkWrapper
              href={element.links[el]}
              key={element.links[el]}
              target='_blank'
            >
              {i === splittedText.length - 1 ? el : `${el} `}
            </TextLinkWrapper>
          )
        } else {
          return `${el} `
        }
      })
    }
    return returnText
  }

  return (
    <MainWrapper>
      <DoubleColumnsContainer>
        {author && (
          <ContentWrapper>
            <TitleWrapper>
              <SectionTitleText
                text={preparedTitleText}
                currentLang={currentLang}
              />
            </TitleWrapper>
            {withSocialLink ? (
              <WithSocialLinkWrapper href={socialLink} target='_blank'>
                <AuthorNameWrapper withSocialLink={withSocialLink}>
                  {author.name}
                </AuthorNameWrapper>
              </WithSocialLinkWrapper>
            ) : (
              <AuthorNameWrapper>{author.name}</AuthorNameWrapper>
            )}

            <AuthorDescWrapper>
              {author.desc.map(element => {
                return <p key={element.text}>{createAuthorText(element)}</p>
              })}
            </AuthorDescWrapper>
            {withCerts && (
              <CertifWrapper>
                {certLogos.map(src => (
                  <CertLogoWrapper key={src}>
                    <Logo imgSrc={src} />
                  </CertLogoWrapper>
                ))}
              </CertifWrapper>
            )}
          </ContentWrapper>
        )}

        <PosterWrapper isSmaller={!withCerts}>
          {posterSrc && (
            <Poster imgSrc={posterSrc} extraStyles='margin: 0 auto;' />
          )}
        </PosterWrapper>
      </DoubleColumnsContainer>
    </MainWrapper>
  )
}

BibleAuthor.propTypes = {
  author: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  certLogos: PropTypes.array,
  posterSrc: PropTypes.string,
  withCerts: PropTypes.bool,
  currentLang: PropTypes.string,
  withSocialLink: PropTypes.bool,
  socialLink: PropTypes.string,
}

BibleAuthor.defaultProps = {
  certLogos: [],
  posterSrc: '',
  author: '',
  withCerts: false,
  currentLang: 'en',
  withSocialLink: false,
  socialLink: '',
}

export default BibleAuthor
