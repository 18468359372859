import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import DoubleColumnsContainer from '@containers/DoubleColumnsContainer'
import { MarkedMainInfoHeader } from '@common/MarkedHeader'
import createSymbolBackgroundStyle from '@common/backgrounds/createSymbolBackgroundStyle'
import { detectBrowser } from '../../../../utils/jsUtils'
import BibleMainInfoRightSide from './BibleMainInfoRightSide'

const MainWrapper = styled.div`
  margin: 80px 2% 0 3%;
  ${props => props.theme.media.lessThan('tablet')`
    margin: 75px 9% 0 9%;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    margin: 75px 5% 0 5%;
  `}
  position:relative;
  &::before {
    ${createSymbolBackgroundStyle({
      width: '80%',
      height: '66%',
      left: '-25px',
      bottom: '8px',
    })}
  }
  &::after {
    ${createSymbolBackgroundStyle({
      width: '26%',
      height: '63%',
      right: '0',
      top: '50px',
    })}
    max-height: 420px;
  }
  ${props => props.theme.media.lessThan('990px')`
    &::before {
      width: 100vw;
    }
    &::after {
      display: none;
    }
  `}
  ${props => props.theme.media.lessThan('tablet')`
    &::before {
      height: 53%;
      left: -10%;
      bottom: 0%;
    }
  `}
`

const MainInfoWrapper = styled.div`
  margin-top: 50px;
`

const MarkedHeaderWrapper = styled.div`
  position: relative;
  margin-left: 23px;
  ${props =>
    props.isFirefox &&
    `
    &::before {
    position: absolute;
    content: '';
    bottom: -5.5px;
    left: -23px;
    background: ${props.theme.mainSectionMarkedHeaderColor};
    height: 100%;
    width: 23px;
    }
`}

  ${props => props.theme.media.lessThan('mobile')`
    margin-left: 5%;
    ${props.isFirefox &&
      `
    &::before {
        bottom: -0.5%;
        width: 7px;
        left: -7px;
    }
    `}

`}
`

const BiblePageMainInfo = ({
  title,
  posterImgSrc,
  children,
  videoId,
  showVideo,
}) => {
  const [isFirefox, detectFirefox] = useState(false)
  useEffect(() => {
    detectFirefox(detectBrowser('firefox'))
  }, [])
  const nextLineTitleMark = '[nexLinePoint]'
  const isTwoLineTitle = title.includes(nextLineTitleMark)
  const createTitleTwoLineText = () => {
    const textArr = title.split(nextLineTitleMark)
    return [
      <span key={textArr[0]}>{textArr[0]}</span>,
      <br key='nextLine' />,
      <span key={textArr[1]}>{textArr[1]}</span>,
    ]
  }
  return (
    <MainWrapper>
      <DoubleColumnsContainer reverseOnMobile>
        <MainInfoWrapper>
          <MarkedHeaderWrapper isFirefox={isFirefox}>
            <MarkedMainInfoHeader>
              {isTwoLineTitle ? [...createTitleTwoLineText()] : title}
            </MarkedMainInfoHeader>
          </MarkedHeaderWrapper>
          {children}
        </MainInfoWrapper>
        <BibleMainInfoRightSide
          posterImgSrc={posterImgSrc}
          videoId={videoId}
          showVideo={showVideo}
        />
      </DoubleColumnsContainer>
    </MainWrapper>
  )
}

BiblePageMainInfo.propTypes = {
  title: PropTypes.string,
  posterImgSrc: PropTypes.string,
  videoId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  showVideo: PropTypes.bool,
}

BiblePageMainInfo.defaultProps = {
  title: '',
  posterImgSrc: '',
  videoId: '',
  children: <></>,
  showVideo: false,
}

export default BiblePageMainInfo
